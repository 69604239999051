import Spinner from "./Spinner";

export default function Button({
  variant = "primary",
  children,
  className,
  disabled,
  type = "button",
  isLoading,
  onClick,
  ref,
}: {
  variant?: "primary" | "secondary";
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  type?: "submit" | "button";
  isLoading?: boolean;
  onClick?: () => void;
  ref?: any;
}) {
  if (variant === "primary") {
    return (
      <button
        disabled={disabled}
        type={type}
        className={`${className} bg-primary px-[1.5rem] py-[0.625rem] rounded-[4rem] text-[0.875rem] font-[500] h-fit hover:bg-[#AAEC87] transition-all disabled:bg-[#C3C3C3] !outline-none focus:!outline-none`}
        onClick={onClick}
        ref={ref}
      >
        {isLoading ? <Spinner /> : children}
      </button>
    );
  } else {
    return (
      <button
        disabled={disabled}
        type={type}
        className={`${className} bg-[white] px-[1.5rem] py-[0.625rem] rounded-[4rem] text-[0.875rem] font-[500] h-fit shadow-button border-[1px] border-[white] hover:bg-[#EDF5E9] transition-all outline-none focus:!outline-none`}
        onClick={onClick}
        ref={ref}
      >
        {isLoading ? <Spinner /> : children}
      </button>
    );
  }
}
