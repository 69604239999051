import moment from "moment-timezone";

export const getESTDateTime = (dateTime: string) => {
  return moment.tz(dateTime, "EST").format("MMM DD, YYYY @hh:mm A zz");
};

export const convertToEST = (date: string, time: string) => {
  const newDate = moment(date).format("YYYY-MM-DD");
  const newTime = moment(time).format("HH:mm");
  const newDateTime = moment
    .tz(`${newDate} ${newTime}`, "America/New_York")
    .toISOString();
  return newDateTime;
};
