import FooterCircle from "assets/images/footer-circle.png";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import Button from "components/Buttons";
import { useRef, useState } from "react";
import useResponsive from "helpers/useResponsive";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { user } from "helpers/api";
const logo = require("assets/images/logo.svg");
const instagram = require("assets/socials/instagram.svg");
const x = require("assets/socials/x.svg");
const linkedin = require("assets/socials/linkedin.svg");
const instagramHover = require("assets/socials/instagram-hover.svg");
const xHover = require("assets/socials/x-hover.svg");
const linkedinHover = require("assets/socials/linkedin-hover.svg");
const responsibleGaming = require("assets/images/responsible-gaming.svg");

export default function Footer() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });
  const { isMobile, isDesktop } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup
    .object({
      email: yup.string().email("Invalid email").required("Email is required"),
      name: yup.string().required("Name is required"),
      message: yup.string().required("Message is required"),
    })
    .required();

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
    // mode: "onSubmit",
  });

  const [hookedYPostion, setHookedYPosition] = useState(0);
  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    setHookedYPosition(latest);
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);
    user
      .inquiry(data)
      .then((res) => {
        toast.success("Inquiry sent successfully");
        setIsLoading(false);
        reset();
      })
      .catch(() => {
        toast.error("There was an error sending inquiry, please try again!");
        setIsLoading(false);
      });
  };

  const { errors } = formState;

  return (
    <footer className="bg-[white] md:mt-[12.5rem] mt-[2rem]">
      <div className="bg-[white]">
        <motion.div
          className="mx-auto min-w-[90%] sm:grid md:flex bg-primary text-[black] xl:px-[10rem] lg:px-[2rem] px-4 md:py-[8.125rem] py-16"
          style={{
            backgroundImage: `url(${require("assets/images/footer-lines.png")})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left bottom",
            backgroundSize: "cover",
            position: "relative",
            width: isDesktop ? hookedYPostion * 16 + 80 + "%" : "100%",
            borderRadius: isDesktop
              ? `${(-(hookedYPostion - 1) + 0.4) * 32}px`
              : "0px",
          }}
          ref={ref}
        >
          <div className="md:w-[920px] sm:grid md:flex m-auto">
            <motion.div
              className="font-sans md:w-1/2 sm:w-full mb-8 md:mb-0 grid gap-[1.5625rem] content-baseline	"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <motion.span className="title md:leading-[120%] font-[500] md:text-[3rem] text-[2.25rem] uppercase">
                Have a <br />
                Question?
              </motion.span>
              <span className="font-[400] text-[black] text-[1.5rem]">
                Contact our team for any inquiries.
              </span>
            </motion.div>
            <form
              className="grid gap-4 md:w-1/2 sm:w-full font-sans"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="grid gap-1">
                <motion.input
                  className="bg-[#97D777] border-[1px] border-[#BBE5A7] rounded-[0.875rem] px-[1.5rem] py-4 text-[black] font-[400] text-[1.125rem] placeholder-[#666666] placeholder-[1.125rem] h-fit focus:outline-none"
                  placeholder="Name"
                  initial={!isMobile ? { opacity: 0 } : { opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: true }}
                  id="name"
                  {...register("name")}
                />
                <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
                  {errors.name?.message}
                </span>
              </div>
              <div className="grid gap-1">
                <motion.input
                  className="bg-[#97D777] border-[1px] border-[#BBE5A7] rounded-[0.875rem] px-[1.5rem] py-4 text-[black] font-[400] text-[1.125rem] placeholder-[#666666] placeholder-[1.125rem] h-fit focus:outline-none"
                  placeholder="Email"
                  initial={!isMobile ? { opacity: 0 } : { opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.125 }}
                  viewport={{ once: true }}
                  id="email"
                  {...register("email")}
                />
                <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
                  {errors.email?.message}
                </span>
              </div>
              <div className="grid gap-1">
                <motion.textarea
                  className="min-h-[160px] bg-[#97D777] border-[1px] border-[#BBE5A7] rounded-[0.875rem] px-[1.5rem] py-4 text-[black] font-[400] text-[1.125rem] placeholder-[#666666] placeholder-[1.125rem] h-fit resize-none focus:outline-none"
                  placeholder="Message"
                  initial={!isMobile ? { opacity: 0 } : { opacity: 0 }}
                  whileInView={{ opacity: 1, x: 0, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.25 }}
                  viewport={{ once: true }}
                  id="message"
                  {...register("message")}
                />
                <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
                  {errors.message?.message}
                </span>
              </div>
              <motion.div
                initial={!isMobile ? { opacity: 0 } : { opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.375 }}
                viewport={{ once: true }}
                className="w-full"
              >
                <Button
                  variant="secondary"
                  className="text-[black] py-[1.125rem] text-[1.125rem] tracking-[-0.72px] font-[400] w-full disabled:cursor-not-allowed disabled:bg-[white]/80 disabled:text-[black] transition-all"
                  disabled={!formState.isValid}
                  type="submit"
                  isLoading={isLoading}
                >
                  Send
                </Button>
              </motion.div>
            </form>
          </div>
        </motion.div>
      </div>
      <div className="md:mt-[6.875rem] mt-[2rem] mb-[4.375rem] px-4">
        <motion.h3
          className="title font-sans uppercase md:text-[3rem] text-[2.25rem] font-[500] text-center"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          Ready to Unlock
          <br /> Premium Selections?
        </motion.h3>
        <motion.div
          className="flex gap-[1.25rem] md:mt-[4.375rem] mt-8"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <Link
            to="/login"
            className="bg-transparent border-[1px] border-[#939393] w-1/2 md:px-[5rem] px-4 md:py-[4.375rem] py-8 rounded-[1.25rem] font-sans font-[400] md:text-[2rem] md:text-[2rem] text-[1.5rem] hover:shadow-button transition-all hover:bg-[black] hover:text-[white] text-center"
          >
            Login
          </Link>
          <Link
            to="/register"
            className="bg-transparent border-[1px] border-[#939393] w-1/2 md:px-[5rem] px-4 md:py-[4.375rem] py-8 rounded-[1.25rem] font-sans font-[400] md:text-[2rem] md:text-[2rem] text-[1.5rem] hover:shadow-button transition-all hover:bg-[black] hover:text-[white] text-center"
          >
            Subscribe
          </Link>
        </motion.div>
      </div>
      <motion.div
        style={{
          backgroundImage: `url(${FooterCircle})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
        className="relative w-full grid justify-center items-center justify-items-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <button
          onClick={
            // scroll to top
            () => {
              // if url is / then scroll to top
              if (window.location.pathname === "/")
                window.scrollTo({ top: 0, behavior: "smooth" });
              // else go to home
              else window.location.href = "/";
            }
          }
        >
          <logo.ReactComponent className="h-[3.75rem] mt-10" />
        </button>
        <div className="flex gap-2 mt-6 mb-5">
          <Link
            to="https://www.instagram.com/wepickuplay"
            className="relative w-[2rem] h-[2rem] cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram.default} alt="instagram" />
            <motion.img
              src={instagramHover.default}
              alt="instagram"
              className="absolute top-0 opacity-0"
              whileHover={{ opacity: 1 }}
            />
          </Link>
          <Link
            to="https://twitter.com/wepickuplay"
            className="relative w-[2rem] h-[2rem] cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            <img src={x.default} alt="x" />
            <motion.img
              src={xHover.default}
              alt="x"
              className="absolute top-0 opacity-0"
              whileHover={{ opacity: 1 }}
            />
          </Link>
        </div>

        <Link
          to="/responsible-gaming"
          className="hover:scale-[1.01] cursor-pointer transition-all duration-200 mb-5"
        >
          <img src={responsibleGaming.default} alt="responsible gaming" />
        </Link>

        <a href="https://www.authorize.net/">
          <img
            src="https://www.authorize.net/content/dam/anet-redesign/reseller/authorizenet-200x50.png"
            // border="0"
            alt="Authorize.net Logo"
            width="140"
            height="20"
          />
        </a>

        <div className="flex gap-1 mt-5 flex-row flex-wrap text-center justify-center mb-2">
          <span className="mb-2 flex">
            © 2024 we pick u play. All Rights Reserved.
          </span>
          <Link
            to="/terms-of-service"
            className="font-[400] text-[1rem] underline"
          >
            Terms of Service
          </Link>
          |
          <Link to="/privacy" className="underline font-[400] text-[1rem]">
            Privacy Policy
          </Link>
        </div>
      </motion.div>
    </footer>
  );
}
