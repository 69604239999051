import { motion } from "framer-motion";
import { PickCard, PickCardSkeleton } from "./Cards";
import useResponsive from "helpers/useResponsive";
import moment from "moment";
import selection from "helpers/api/selection";
import { useQuery } from "react-query";
import Spinner from "components/Spinner";
import { useState } from "react";
const notFoundIcon = require("assets/images/not-found.svg");
const chevronIcon = require("assets/images/chevron-black.svg");

export default function Yesterday() {
  const { isTablet, isMobileLandScape } = useResponsive();
  const { data, isLoading } = useQuery("yesterdays-selections", () =>
    selection.get(moment().subtract(1, "days").format("YYYY-MM-DD"))
  );
  const [isTabletDropdownOpen, setisTabletDropdownOpen] = useState(false);

  if (isLoading) {
    return (
      <div
        className={`p-[1.5rem] bg-[white] rounded-[12px] ${
          isTablet ? "w-full" : "w-full"
        } h-fit overflow-hidden`}
        // initial={{
        //   opacity: 0,
        // }}
        // whileInView={{ opacity: 1 }}
        // transition={{ duration: 0.5 }}
        // viewport={{ once: true }}
        // exit={{ opacity: 0 }}
        style={{
          height: "fit-content",
        }}
      >
        <PickCardSkeleton />
      </div>
    );
  }

  return (
    <motion.div
      className={`pb-[1.5rem] bg-[white] rounded-[12px] w-full overflow-hidden`}
      style={{
        minHeight: !isMobileLandScape ? "4.6875rem" : "6.625rem",
      }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      animate={
        isTabletDropdownOpen
          ? "open"
          : isMobileLandScape
          ? "closedMobile"
          : "closed"
      }
      variants={{
        open: { maxHeight: "100%" },
        closedMobile: { maxHeight: "6.625rem" },
        closed: { maxHeight: "4.6875rem" },
      }}
      exit={{ opacity: 0 }}
    >
      <button
        className={`p-[1.5rem] text-[1.125rem] font-[500] uppercase flex justify-between w-full text-start items-center transition-all hover:opacity-70`}
        onClick={() => setisTabletDropdownOpen(!isTabletDropdownOpen)}
      >
        <div className="md:flex grid gap-1">
          Previous selections
          <span className="text-[#8A8A8A]">(YESTERDAY’S SELECTIONS)</span>
        </div>
        <motion.div
          className={isTabletDropdownOpen ? "transform rotate-180" : ""}
          initial={false}
          animate={isTabletDropdownOpen ? "open" : "closed"}
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 },
          }}
        >
          <chevronIcon.ReactComponent />
        </motion.div>
      </button>

      <div className="grid gap-[1.125rem] p-4">
        {data.length > 0 ? (
          data.map((item: any, index: number) => (
            <PickCard key={index} data={item} showResult />
          ))
        ) : (
          <div className="grid gap-[1.125rem] my-[10rem] items-center justify-items-center">
            <notFoundIcon.ReactComponent />
            <span className="font-[500] text-[1.125rem]">
              No previous selections found
            </span>
          </div>
        )}
      </div>
    </motion.div>
  );
}
