/* eslint-disable import/no-anonymous-default-export */
import client from "./client";

export default {
  get: () => client.get(`/subscriptions`).then((res) => res.data),
  current: () =>
    client
      .get(`/users/${localStorage.getItem("id")}/subscriptions`)
      .then((res) => res.data[0]),
  updateSubscription: (data: any) =>
    client
      .post(`/users/${localStorage.getItem("id")}/subscriptions`, data)
      .then((res) => res.data),
  deleteSubscription: (data: any) =>
    client
      .request({
        url: `/users/${localStorage.getItem("id")}/subscriptions`,
        method: "DELETE",
        data: data,
      })
      .then((res) => res.data),
  getPromo: (promo: string) =>
    client.get(`/promo-codes/${promo}`).then((res) => res.data),
};
