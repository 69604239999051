import React from "react";
import Hero from "./Hero";
import About from "./About";
import Subscription from "./Subscription";
import Product from "./Product";
import Services from "./Services";
import FAQ from "./FAQ";
import Footer from "./Footer";
import ScrollToTop from "helpers/ScrollToTop";
import Menu from "components/Menu";

const Homepage: React.FC = () => {
  return (
    <body className="bg-[white]">
      <ScrollToTop />
      <Menu type="landing" transparentTop />
      <Hero />
      <About />
      <Subscription />
      <Product />
      <Services />
      <FAQ />
      <Footer />
    </body>
  );
};

export default Homepage;
