/* eslint-disable import/no-anonymous-default-export */
import client from "./client";

export default {
  login: ({ email, password }: { email: string; password: string }) =>
    client.post("/auth/login", { email, password }).then((res) => res.data),
  register: ({
    email,
    password,
    firstName,
    lastName,
    mobile,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    mobile: string;
  }) =>
    client
      .post("/auth/register", { email, password, firstName, lastName, mobile })
      .then((res) => res.data),
  forgotPassword: ({ email }: { email: string }) =>
    client
      .post("/auth/forgot-password", {
        email,
        resetPasswordUrl: window.location.origin + "/reset-password",
      })
      .then((res) => res.data),
  resetPassword: ({
    password,
    resetPasswordToken,
  }: {
    password: string;
    resetPasswordToken: string | null;
  }) =>
    client
      .post("/auth/reset-password", { password, resetPasswordToken })
      .then((res) => res.data),
};
