import Button from "components/Buttons";
import Input from "components/Input";
import { motion } from "framer-motion";
import { useQuery } from "react-query";
import { subscription } from "helpers/api";
import { useState } from "react";
import toast from "react-hot-toast";
import Modal from "components/Modal";
import PaymentSuccess from "./PaymentSuccess";
import Success from "modules/user/edit-profile/Success";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "components/Checkbox";
import styled from "styled-components";
import { useAuth } from "helpers/contexts/AuthContext";

export default function Payment({
  state,
  isOpen,
  handleModal,
  refetch,
}: {
  state: {
    plan: string;
    duration: string;
  };
  isOpen: boolean;
  handleModal: () => void;
  refetch?: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    cardHolderName: "",
    subscriptionId: "",
    duration: "MONTHLY",
    expiryYear: "",
    expiryMonth: "",
    expiryDate: "",
    subscriptionPaymentType: "CREDIT_CARD",
    cvv: "",
    cardNumber: "",
    tempPromoCode: "",
    promoCode: "",
  });
  const [errors, setErrors] = useState({
    cardNumber: "",
    expiryDate: "",
    promo: "",
  });
  const [success, setSuccess] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [tos, setTos] = useState(false);
  const [plus18, setPlus18] = useState(false);
  const { data, isLoading } = useQuery("subscriptions", subscription.get);
  const navigate = useNavigate();
  const { refetchUser } = useAuth();

  const handleChanges = (e: any) => {
    if (e.target.id === "cardNumber") {
      if (e.target.value.length > 19) {
        return;
      }
      // validate card number
      if (e.target.value.length !== 19) {
        setErrors({ ...errors, cardNumber: "Invalid card number" });
      } else {
        setErrors({ ...errors, cardNumber: "" });
      }
      const value = e.target.value
        .replace(/\s/g, "")
        .match(/.{1,4}/g)
        ?.join(" ");
      setForm({ ...form, [e.target.id]: value });
    } else if (e.target.id === "expiryDate") {
      if (e.target.value.length > 5) {
        return;
      }
      // validate expiry date
      if (e.target.value.length !== 5) {
        setErrors({ ...errors, expiryDate: "Invalid expiry date" });
      } else {
        setErrors({ ...errors, expiryDate: "" });
      }
      const value = e.target.value
        .replace(/\//g, "")
        .match(/.{1,2}/g)
        ?.join("/");
      setForm({ ...form, [e.target.id]: value });
    } else setForm({ ...form, [e.target.id]: e.target.value });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const promise = {
      subscriptionId: state.plan,
      duration:
        state.duration === "monthly"
          ? "MONTHLY"
          : state.duration === "half_yearly"
          ? "HALF_YEARLY"
          : "YEARLY",
      expiryYear: 20 + form.expiryDate.split("/")[1],
      expiryMonth: form.expiryDate.split("/")[0],
      cardNumber: form.cardNumber.replace(/\s/g, ""),
      subscriptionPaymentType: "CREDIT_CARD",
      promoCode: form.promoCode,
    };
    subscription
      .updateSubscription(promise)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        refetchUser();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message || "An error occurred");
        setLoading(false);
      });
  };
  const applyPromo = (id: string) => {
    setForm({ ...form, promoCode: id });
    subscription
      .getPromo(form.tempPromoCode)
      .then((res) => {
        setDiscount(res.discount);
        if (refetch) refetch();
        setErrors({
          ...errors,
          promo: "",
        });
      })
      .catch((err) => {
        // toast.error(err.response.data.message);
        setDiscount(0);
        setErrors({
          ...errors,
          promo: err.response.data.message,
        });
      });
  };

  if (isLoading) {
    return (
      <Modal isOpen={true} handleModal={() => console.log("a")}>
        <div>Loading...</div>
      </Modal>
    );
  }

  const selectedPlan = data.filter((plan: any) => plan.id === state.plan)[0];

  return (
    <Modal
      isOpen={isOpen}
      handleModal={handleModal}
      title={!success ? "Complete payment" : ""}
      width="600px"
    >
      {success ? (
        <div className="mb-12">
          <Success
            onClose={() => {
              setSuccess(false);
              handleModal();
            }}
            title="Payment Successful"
            description="Your payment has been processed successfully!"
            button="Go to Dashboard"
            onClick={() => {
              if (refetch) refetch();
              handleModal();
              setSuccess(false);
              navigate("/dashboard");
            }}
          />
        </div>
      ) : (
        <form
          className="grid gap-[1.25rem] w-full flex"
          onSubmit={handleSubmit}
        >
          <div className="rounded-[20px] w-full border-[1px] border-solid border-[#DDD] p-[1.25rem]">
            <div className="flex justify-between">
              <h2 className="uppercase text-[1.125rem] font-[600]">
                {selectedPlan.name} PLAN
              </h2>
              <h3 className="text-[#747474] text-[1rem] font-[500]">
                $
                {state.duration === "monthly"
                  ? selectedPlan?.priceMonthly
                  : state.duration === "half_yearly"
                  ? selectedPlan?.priceHalfYearly
                  : selectedPlan?.priceYearly}{" "}
                /
                {state.duration === "monthly"
                  ? "month"
                  : state.duration === "half_yearly"
                  ? "6 months"
                  : "year"}
              </h3>
            </div>
            <div className="my-[1.25rem] border-t-solid border-t-[1px] border-t-[#D4D4D4]" />
            <div className="grid gap-2">
              <div className="mb-3">
                <div className="flex relative">
                  <Input
                    placeholder="Promo code"
                    className="!pr-16"
                    parentClassName="w-full"
                    id="tempPromoCode"
                    onChange={handleChanges}
                  />
                  <button
                    className="text-[#04BD38] font-[500] absolute"
                    style={{
                      right: "1rem",
                      top: "55%",
                      transform: "translateY(-50%)",
                    }}
                    type="button"
                    onClick={() => applyPromo(form.tempPromoCode)}
                  >
                    Apply
                  </button>
                </div>
                {errors.promo && (
                  <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
                    {errors.promo}
                  </span>
                )}
              </div>
              <div className="flex justify-between">
                <span>Subtotal</span>
                <span className="font-[500]">
                  $
                  {state.duration === "monthly"
                    ? selectedPlan?.priceMonthly
                    : state.duration === "half_yearly"
                    ? selectedPlan?.priceHalfYearly
                    : selectedPlan?.priceYearly}
                </span>
              </div>
              {/* <div className="flex justify-between">
              <span>Tax</span>
              <span className="font-[500]">????</span>
            </div> */}
              <div className="flex justify-between">
                <span>Discount</span>
                <span className="font-[500] text-[#04BD38]">
                  $
                  {(discount *
                    (state.duration === "monthly"
                      ? selectedPlan?.priceMonthly
                      : state.duration === "half_yearly"
                      ? selectedPlan?.priceHalfYearly
                      : selectedPlan?.priceYearly)) /
                    100 || "0"}
                </span>
              </div>
            </div>
            <div className="my-[1.25rem] border-t-solid border-t-[1px] border-t-[#D4D4D4]" />
            <div className="flex justify-between">
              <span className="font-[500]">Total billed now</span>
              <span className="font-[600]">
                $
                {discount !== 0 &&
                  discount &&
                  (
                    (state.duration === "monthly"
                      ? selectedPlan?.priceMonthly
                      : state.duration === "half_yearly"
                      ? selectedPlan?.priceHalfYearly
                      : selectedPlan?.priceYearly) -
                    (discount *
                      (state.duration === "monthly"
                        ? selectedPlan?.priceMonthly
                        : state.duration === "half_yearly"
                        ? selectedPlan?.priceHalfYearly
                        : selectedPlan?.priceYearly)) /
                      100
                  ).toFixed(2)}
                {(discount === 0 || !discount) &&
                  (state.duration === "monthly"
                    ? selectedPlan?.priceMonthly
                    : state.duration === "half_yearly"
                    ? selectedPlan?.priceHalfYearly
                    : selectedPlan?.priceYearly)}
              </span>
            </div>
          </div>
          <div className="grid bg-[#EDEDF6] p-[1.5rem] rounded-[20px] gap-[1.25rem]">
            <h2 className="uppercase text-[1.125rem] font-[600]">
              Credit Card details
            </h2>
            <div className="md:flex grid gap-3 w-full">
              <Input
                label="Card number"
                placeholder="XXXX XXXX XXXX XXXX"
                parentClassName="md:w-3/4 w-full content-start"
                className="h-fit bg-[white]"
                id="cardNumber"
                onChange={handleChanges}
                value={form.cardNumber}
                error={errors.cardNumber}
              />
              <Input
                label="Expiration"
                placeholder="MM/YY"
                parentClassName="md:w-1/4 w-full content-start"
                className="h-fit bg-[white]"
                id="expiryDate"
                onChange={handleChanges}
                value={form.expiryDate}
                error={errors.expiryDate}
              />
            </div>
          </div>
          {/* I agree to tos */}
          <div className="flex items-center">
            <Wrapper>
              <label
                htmlFor="tos"
                className="flex items-center gap-1 container"
              >
                <input
                  type="checkbox"
                  id="tos"
                  className="w-[1.375rem] h-[1.375rem] stroke-[#8D8E92]"
                  onChange={() => setTos(!tos)}
                />
                <span className="checkmark"></span>I agree to the{" "}
                <Link
                  to="/terms-of-service"
                  className="text-[#04BD38] font-[500] hover:underline"
                >
                  Terms of Service
                </Link>
              </label>
            </Wrapper>
          </div>
          <div className="flex items-center">
            <Wrapper>
              <label
                htmlFor="plus18"
                className="flex items-center gap-1 container"
              >
                <input
                  type="checkbox"
                  id="plus18"
                  className="w-[1.375rem] h-[1.375rem] stroke-[#8D8E92]"
                  onChange={() => setPlus18(!plus18)}
                />
                <span className="checkmark"></span>I am over 18 years old and
                agree to the{" "}
                <Link
                  to="/responsible-gaming"
                  className="text-[#04BD38] font-[500] hover:underline"
                >
                  Responsible Gaming
                </Link>
              </label>
            </Wrapper>
          </div>
          <Button
            className="w-full py-[0.875rem]"
            type="submit"
            disabled={!form.cardNumber || !form.expiryDate || !tos || !plus18}
            isLoading={loading}
          >
            Subscribe
          </Button>
        </form>
      )}
    </Modal>
  );
}

const Wrapper = styled.div`
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.375rem;
    width: 1.375rem;
    background-color: transparent;
    border: 1px #a4a5a8 solid;
    border-radius: 4px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #73c947;
    border: 1px solid #73c947;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
