import Button from "components/Buttons";
import Input from "components/Input";
import Menu from "components/Menu";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import { useEffect, useState } from "react";
import passwordValidation from "helpers/passwordValidation";
import { useAuth } from "helpers/contexts/AuthContext";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useResponsive from "helpers/useResponsive";
const eyeSlashIcon = require("assets/images/eye-slash.svg");
const eyeIcon = require("assets/images/eye.svg");

export default function Register() {
  const { register: registerCall } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmShown, setConfirmShown] = useState(false);
  const { isMobile } = useResponsive();

  const schema = yup
    .object({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      mobile: yup
        .string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Invalid mobile number"),
      password: yup
        .string()
        .test(
          "password",
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
          (value: any) => passwordValidation(value)
        ),
      confirm: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match"), // Fix: Allow null as a valid value
    })
    .required();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);
    registerCall(data, setIsLoading);
  };

  const { errors, isValid } = formState;

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-[100vw] grid content-center justify-items-center">
      <Menu type="register" />

      <motion.div
        className="px-4 w-full flex justify-center"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.125 }}
        viewport={{ once: true }}
        exit={{ opacity: 0 }}
      >
        <form
          className="bg-[#FCFBFD] max-w-[32rem] rounded-[24px] p-[2.5rem] h-fit mt-[8rem] mb-[4rem]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1 className="!text-[1.5rem] title font-[500] mb-[1.5rem] uppercase">
            Register
          </h1>
          <div className="grid gap-4 mb-6">
            <div className="flex gap-[0.75rem] flex-wrap md:flex-nowrap w-full">
              <Input
                id="firstName"
                placeholder="Enter first name"
                label="First name"
                parentClassName="w-full"
                register={register}
                error={errors.firstName?.message}
              />
              <Input
                id="lastName"
                placeholder="Enter last name"
                label="Last name"
                parentClassName="w-full"
                error={errors.lastName?.message}
                register={register}
              />
            </div>
            <Input
              id="email"
              placeholder="Enter email address"
              label="Email"
              type="email"
              error={errors.email?.message}
              register={register}
            />
            <Input
              id="mobile"
              placeholder="Enter Phone number"
              label="Phone number"
              error={errors.mobile?.message}
              register={register}
            />
            <div className="relative">
              <Input
                id="password"
                placeholder="Create password"
                label="Password"
                type={passwordShown ? "text" : "password"}
                error={errors.password?.message}
                register={register}
                isPasswordField
                className="pr-12"
              />
              <button
                className="absolute right-[1.125rem] top-[2.75rem]"
                type="button"
                onClick={() => setPasswordShown(!passwordShown)}
                tabIndex={-1}
              >
                <img
                  src={passwordShown ? eyeIcon.default : eyeSlashIcon.default}
                  alt="eye"
                />
              </button>
            </div>
            <div className="relative">
              <Input
                id="confirm"
                placeholder="Re-enter password"
                label="Confirm password"
                type={confirmShown ? "text" : "password"}
                error={errors.confirm?.message}
                register={register}
                isPasswordField
                className="pr-12"
              />
              <button
                className="absolute right-[1.125rem] top-[2.75rem]"
                type="button"
                onClick={() => setConfirmShown(!confirmShown)}
                tabIndex={-1}
              >
                <img
                  src={confirmShown ? eyeIcon.default : eyeSlashIcon.default}
                  alt="eye"
                />
              </button>
            </div>
          </div>
          <Button
            className="w-full py-[0.875rem] text-4 mb-[1.5rem]"
            type="submit"
            isLoading={isLoading}
            disabled={!isValid || isLoading}
          >
            Register
          </Button>
          <div className="text-center">
            <span className="text-[0.875rem] font-[500]">
              Have an account?{" "}
            </span>
            <Link
              className="text-[0.875rem] font-[600] text-primary opacity-[0.8] transition-all hover:opacity-100 hover:scale-[1.01] outline-none focus:!outline-primary"
              to="/login"
            >
              Log in
            </Link>
          </div>
        </form>
      </motion.div>

      {!isMobile && <Footer className="relative" />}
    </div>
  );
}
