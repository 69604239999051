import NCAABIcon from "assets/sports/ncaab.png";
import NBAIcon from "assets/sports/nba.png";
import NFLIcon from "assets/sports/nfl.png";
import MLBIcon from "assets/sports/mlb.png";

export const sports = [
  { name: "NCAAB", icon: NCAABIcon },
  { name: "NBA", icon: NBAIcon },
  { name: "NFL", icon: NFLIcon },
  { name: "MLB", icon: MLBIcon },
  { name: "NCAAF", icon: NCAABIcon },
];

export const WAGER = {
  MoneyLine: "moneyLine",
  Spread: "spread",
  Total: "total",
  RunLine: "runLine",
};

export const WAGER_LABEL: { [key: string]: string } = {
  moneyLine: "Money Line",
  spread: "Spread",
  total: "Total",
  runLine: "Run Line",
};
