const searchIcon = require("assets/images/search.svg");

export default function Input({
  id,
  placeholder,
  label,
  className,
  type,
  onChange,
  error,
  onBlur,
  parentClassName,
  tabIndex,
  register,
  isPasswordField,
  search,
  value,
}: {
  id?: string;
  placeholder?: string;
  label?: string;
  className?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  parentClassName?: string;
  tabIndex?: number;
  props?: any;
  register?: any;
  isPasswordField?: boolean;
  search?: boolean;
  value?: string;
}) {
  if (search) {
    return (
      <div className="relative">
        <input
          id={id}
          className={`${className} md:min-w-[17.5rem] border-[#E0E0EA] border-[1px] pl-12 p-[1.125rem] rounded-[8px] w-full bg-[transparent] focus:!border-primary`}
          placeholder={placeholder}
          type={type || "text"}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={!isPasswordField && 50}
          tabIndex={tabIndex}
          {...(register && register(`${id}`))}
        />
        <searchIcon.ReactComponent className="absolute top-[50%] left-[1.25rem] transform -translate-y-1/2" />
      </div>
    );
  }

  return (
    <label htmlFor={id} className={`${parentClassName} grid gap-1`}>
      <span className="mb-1 text-[0.875rem] font-[400] cursor-pointer">
        {label}
      </span>
      <input
        id={id}
        className={`${className} border-[#E0E0EA] border-[1px] p-[1.125rem] rounded-[8px] w-full bg-[transparent] focus:shadow-none focus:!outline-none focus:!border-primary`}
        placeholder={placeholder}
        type={type || "text"}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={!isPasswordField && 50}
        tabIndex={tabIndex}
        value={value}
        {...(register && register(`${id}`))}
      />
      {error && (
        <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
          {error}
        </span>
      )}
    </label>
  );
}
