import Button from "components/Buttons";
import Input from "components/Input";
import Menu from "components/Menu";
import Footer from "../Footer";
import { useEffect, useState } from "react";
import { auth } from "helpers/api";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import useResponsive from "helpers/useResponsive";
import Success from "modules/user/edit-profile/Success";
const ArrowIcon = require("assets/images/arrow-left.svg").default;

export default function ForgotPassword() {
  const [email, setEmail] = useState<string>("");
  const { isDesktop } = useResponsive();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSuccessClose = () => {
    setSuccess(false);
  };
  const handleResend = () => {
    setIsLoading(true);
    auth
      .forgotPassword({ email: email })
      .then(() => {
        toast.success("Password reset link sent to your email");
        setIsLoading(false);
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };

  // Success Component
  function SuccessComponent() {
    return (
      <form className="bg-[#FCFBFD] max-w-[26.125rem] rounded-[24px] p-[2.5rem] h-fit text-center">
        <Success
          onClose={handleSuccessClose}
          title="Reset password link sent"
          description={`We’ve sent the reset password link to your registered email ${
            email ? email : "address"
          }!`}
          button="Go to Login"
          subFooter={
            <>
              <span>Didn’t receive the link?</span>
              <button
                className="text-primary font-[700] ml-1"
                onClick={handleResend}
                type="button"
              >
                Resend
              </button>
            </>
          }
        />
      </form>
    );
  }

  //  Main Forgot Password Component

  return (
    <div className="w-[100vw] md:max-lg:[auto] h-[100vh] lg:h-[100vh] grid content-center justify-items-center">
      <Menu type="landing" />
      <motion.div
        className="px-4 w-full flex justify-center md:pt-[100px] lg:pt-[0px] md:pb-6 lg:pt-[0px]  md:max-lg:mt-64 overflow-auto "
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.125 }}
        viewport={{ once: true }}
        exit={{ opacity: 0 }}
      >
        {success ? (
          <SuccessComponent />
        ) : (
          <ForgotPasswordComponent
            email={email}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            setSuccess={setSuccess}
          />
        )}
      </motion.div>
      {isDesktop && <Footer />}
    </div>
  );
}

function ForgotPasswordComponent({
  email,
  handleChange,
  setSuccess,
}: {
  email: string;
  handleChange: any;
  setSuccess: any;
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    auth
      .forgotPassword({ email: email })
      .then(() => {
        toast.success("Password reset link sent to your email");
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (emailRegex.test(email) && email.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email]);

  return (
    <form
      className="bg-[#FCFBFD] max-w-[28.125rem] rounded-[24px] p-[2.5rem] h-fit"
      onSubmit={handleSubmit}
    >
      <button
        className="flex gap-[0.375rem] items-center mb-[1.625rem]"
        type="button"
        onClick={() => {
          window.history.back();
        }}
      >
        <img src={ArrowIcon} alt="arrow" />
        <span className="leading-[160%]">Back</span>
      </button>
      <h1 className="!text-[1.5rem] title font-[500] mb-[0.5rem] uppercase">
        Forgot password?
      </h1>
      <p className="mb-6 text-[0.875rem] md:text-[1rem]">
        Please provide your registered email address, and we'll send you a link
        to reset your password.
      </p>
      <Input
        id="email"
        placeholder="Enter email address"
        label="Email"
        value={email}
        onChange={handleChange}
      />
      <Button
        disabled={isDisabled || isLoading}
        className="w-full py-[0.875rem] text-4 mt-6"
        isLoading={isLoading}
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
}
