import Button from "components/Buttons";
import Input from "components/Input";
import Modal from "components/Modal";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { user } from "helpers/api";
import toast from "react-hot-toast";
import passwordValidation from "helpers/passwordValidation";
import Success from "./Success";
const eyeSlashIcon = require("assets/images/eye-slash.svg");
const eyeIcon = require("assets/images/eye.svg");

export default function EditPassword() {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const schema = yup.object({
    oldPassword: yup
      .string()
      .test(
        "password",
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
        (value: any) => passwordValidation(value)
      )
      .required("Old password is required"),
    newPassword: yup
      .string()
      .test(
        "password",
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
        (value: any) => passwordValidation(value)
      )
      .required("New password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("newPassword")], "Passwords must match"),
  });
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const handleChangePassword = (data: any) => {
    setIsLoading(true);
    user
      .changePassword(data.oldPassword, data.newPassword)
      .then(() => {
        setIsLoading(false);
        toast.success("Password changed successfully");
        reset();
        setSuccess(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleSuccessClose = () => {
    setSuccess(false);
  };

  return (
    <div>
      {!success ? (
        <motion.form
          onSubmit={handleSubmit(handleChangePassword)}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.125 }}
        >
          <div className="grid gap-[1.5rem]">
            <div>
              <div className="relative">
                <Input
                  label="Existing password"
                  id="oldPassword"
                  register={register}
                  type={passwordShown ? "text" : "password"}
                  className="pr-12"
                />
                <button
                  className="absolute right-[1.125rem] top-[53%] -transform-[translate-y-1/2]"
                  type="button"
                  onClick={() => setPasswordShown(!passwordShown)}
                  tabIndex={-1}
                >
                  <img
                    src={passwordShown ? eyeIcon.default : eyeSlashIcon.default}
                    alt="eye"
                  />
                </button>
              </div>
              {formState.errors.oldPassword?.message && (
                <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
                  {formState.errors.oldPassword?.message}
                </span>
              )}
            </div>
            <div>
              <div className="relative">
                <Input
                  label="New password"
                  id="newPassword"
                  register={register}
                  type={newPasswordShown ? "text" : "password"}
                  className="pr-12"
                />
                <button
                  className="absolute right-[1.125rem] top-[53%] -transform-[translate-y-1/2]"
                  type="button"
                  onClick={() => setNewPasswordShown(!newPasswordShown)}
                  tabIndex={-1}
                >
                  <img
                    src={
                      newPasswordShown ? eyeIcon.default : eyeSlashIcon.default
                    }
                    alt="eye"
                  />
                </button>
              </div>
              {formState.errors.newPassword?.message && (
                <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
                  {formState.errors.newPassword?.message}
                </span>
              )}
            </div>
            <div>
              <div className="relative">
                <Input
                  label="Confirm password"
                  id="confirmPassword"
                  register={register}
                  type={confirmPasswordShown ? "text" : "password"}
                  className="pr-12"
                />
                <button
                  className="absolute right-[1.125rem] top-[53%] -transform-[translate-y-1/2]"
                  type="button"
                  onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
                  tabIndex={-1}
                >
                  <img
                    src={
                      confirmPasswordShown
                        ? eyeIcon.default
                        : eyeSlashIcon.default
                    }
                    alt="eye"
                  />
                </button>
              </div>
              {formState.errors.confirmPassword?.message && (
                <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
                  {formState.errors.confirmPassword?.message}
                </span>
              )}
            </div>
            <Button
              className="w-full py-[0.875rem]"
              disabled={!formState.isValid || isLoading}
              type="submit"
              isLoading={isLoading}
            >
              Change
            </Button>
          </div>
        </motion.form>
      ) : (
        <Success
          onClose={handleSuccessClose}
          title="Password changed"
          description="Your password has been changed successfully!"
          button="Go to Login"
        />
      )}
    </div>
  );
}
