import UserLayout from "layouts/UserLayout";
import { motion } from "framer-motion";
import useResponsive from "helpers/useResponsive";
import { useQuery } from "react-query";
import { user } from "helpers/api";
import capitalize from "helpers/hooks/capitalize";
import Selection from "./Selection";
import Yesterday from "./Yesterday";
import Today from "./Today";
// import EditProfile from "../edit-profile";

export default function Picks() {
  const { isTablet } = useResponsive();
  const { data, isLoading } = useQuery("user", user.get);

  if (isLoading) return null;

  return (
    <UserLayout>
      <div className="md:mb-0 mb-[1.25rem]">
        <Selection />
      </div>
      <div className={`${isTablet ? "grid" : "grid"} gap-[1.25rem]`}>
        <Today />
        <Yesterday />
      </div>
    </UserLayout>
  );
}
