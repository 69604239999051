import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { auth, subscription } from "helpers/api";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
// import { useQuery } from 'react-query';

const AuthContext = createContext<any>(null);
AuthContext.displayName = "AuthContext";

function AuthProvider(props: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const register = useCallback(
    async (
      formData: {
        firstName: string;
        lastName: string;
        email: string;
        mobile: string;
        password: string;
      },
      setIsLoading: (value: boolean) => void
    ) => {
      try {
        const res = await auth.register(formData);
        toast.success("Registration successful");
        // console.log(res);
        // set token and user in local storage
        localStorage.setItem("token", res.accessToken);
        // localStorage.setItem("user", JSON.stringify(res.data.user));
        setIsLoading(false);
        navigate("/login");
      } catch (err: any) {
        toast.error(err.response.data.message);
        setIsLoading(false);
      }
    },
    [navigate]
  );
  const login = useCallback(
    async (
      formData: {
        email: string;
        password: string;
      },
      setIsLoading: (value: boolean) => void
    ) => {
      try {
        const res = await auth.login(formData);
        localStorage.setItem("token", res.accessToken);
        localStorage.setItem("id", res.id);
        const response = await subscription.current();
        if (!response?.subscriptionId) {
          navigate("/plan");
        } else {
          navigate("/dashboard");
          // set user in local storage
          localStorage.setItem("user", JSON.stringify(response));
        }
        setIsLoading(false);
      } catch (err: any) {
        toast.error(err.response.message || "Invalid credentials");
        setIsLoading(false);
      }
    },
    []
  );
  const logout = useCallback(() => {
    localStorage.removeItem("token");
    // localStorage.removeItem("user");
    navigate("/login");
  }, [navigate]);
  const getToken = () => {
    return localStorage.getItem("token");
  };
  const user = () => {
    try {
      return JSON.parse(localStorage.getItem("user") || "{}");
    } catch (e) {
      return {};
    }
  };
  const refetchUser = async () => {
    const response = await subscription.current();
    console.log("refetchUser");
    localStorage.setItem("user", JSON.stringify(response));
  };

  const value = useMemo(
    () => ({
      register,
      login,
      logout,
      getToken,
      user: user(),
      refetchUser,
    }),
    [register, login, logout]
  );

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
