import Tippy from "@tippyjs/react";
const Triangle = require("assets/images/triangle.svg");

const infoIcon = require("assets/images/info.svg");
export function NoteTooltip({ note }: { note: string }) {
  return (
    <Tippy
      className="my-tooltip"
      arrow={true}
      interactive={true}
      hideOnClick={true}
      content={
        <div className="relative">
          <div className="text-[black] z-[11] text-[0.875rem] leading-[160%] px-[1.25rem] py-[1.125rem] bg-[white] rounded-[10px] shadow-[0px_4px_24px_0px_rgba(0,0,0,0.19)]">
            {note}
          </div>
          <Triangle.ReactComponent
            className="absolute -bottom-2"
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 10,
            }}
          />
        </div>
      }
    >
      <div className="rounded-md bg-[white] text-primary font-semibold border border-primary flex gap-1.5 justify-center items-center px-3 cursor-pointer py-1.5">
        Note <infoIcon.ReactComponent className="w-5 h-5" />
      </div>
    </Tippy>
  );
}
