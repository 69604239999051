import NBAIcon from "assets/images/nba.png";
import NFLIcon from "assets/images/nfl.png";
import NCAABIcon from "assets/images/ncaab.png";
import NCAAFIcon from "assets/images/ncaaf.png";
import NCAAIcon from "assets/images/ncaa.png";
import MLBIcon from "assets/images/mlb.png";
import { motion } from "framer-motion";

export default function Services() {
  return (
    <section
      id="services"
      className="grid justify-items-center md:mt-[11.25rem] mt-[5rem] px-4"
    >
      <motion.h3
        className="title font-sans uppercase md:text-[3rem] text-[2.25rem] font-[500] text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        Where we play
      </motion.h3>
      <motion.p
        className="mt-[0.75rem] max-w-[600px] text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        Our selection portfolio spans across moneylines, totals, spreads,
        two-team parlays, and two-team teasers, providing a diverse range of
        betting options for each sport.
      </motion.p>
      <motion.div
        className="mt-[3.75rem] md:flex gap-2 grid w-full justify-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <div className="flex gap-2">
          <motion.div
          // initial={{ opacity: 0, y: 50 }}
          // whileInView={{ opacity: 1, y: 0 }}
          // transition={{ duration: 0.5 }}
          // viewport={{ once: true }}
          >
            <img src={NBAIcon} alt="NBA" />
          </motion.div>
          <motion.div
          // initial={{ opacity: 0, y: 50 }}
          // whileInView={{ opacity: 1, y: 0 }}
          // transition={{ duration: 0.5, delay: 0.25 }}
          // viewport={{ once: true }}
          >
            <img src={NFLIcon} alt="NFL" />
          </motion.div>
        </div>
        <div className="flex gap-2">
          <motion.div
          // initial={{ opacity: 0, y: 50 }}
          // whileInView={{ opacity: 1, y: 0 }}
          // transition={{ duration: 0.5, delay: 0.5 }}
          // viewport={{ once: true }}
          >
            <img src={MLBIcon} alt="MLB" />
          </motion.div>
          <motion.div
          // initial={{ opacity: 0, y: 50 }}
          // whileInView={{ opacity: 1, y: 0 }}
          // transition={{ duration: 0.5, delay: 0.75 }}
          // viewport={{ once: true }}
          >
            <img src={NCAAIcon} alt="NCAA" />
          </motion.div>
        </div>
        {/* <div>
          <motion.div
            className="md:w-full w-[calc(50%-0.25rem)] flex mx-auto"
            // initial={{ opacity: 0, y: 50 }}
            // whileInView={{ opacity: 1, y: 0 }}
            // transition={{ duration: 0.5, delay: 1 }}
            // viewport={{ once: true }}
          >
            <img src={NCAAFIcon} alt="NCAAF" />
          </motion.div>
        </div> */}
      </motion.div>
    </section>
  );
}
