import Button from "components/Buttons";
import PlanCard, { PlanCardSkeleton } from "components/PlanCard";
import { motion } from "framer-motion";
import useResponsive from "helpers/useResponsive";
import UserLayout from "layouts/UserLayout";
import UpgradePlan from "../upgrade";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { subscription } from "helpers/api";
import Payment from "modules/payment";

export default function Plan() {
  const { isMobile } = useResponsive();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    id: "",
    duration: "",
  });
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const { data, isLoading, refetch } = useQuery(
    "user-subscription",
    subscription.current
  );

  if (isLoading) {
    return (
      <div className="min-h-[100vh] h-[100%] bg-[white]">
        <UserLayout plan>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.125 }}
          >
            <div
              className={`${
                isMobile ? "block" : "grid"
              } items-center justify-center content-center`}
            >
              <h1 className="title uppercase md:text-[2.75rem] text-[1.25rem] font-[500] md:text-center text-start md:mb-[2.625rem] mb-[1.5rem]">
                Your plan
              </h1>
              <PlanCardSkeleton />
            </div>
          </motion.div>
        </UserLayout>
      </div>
    );
  }

  return (
    <div className="min-h-[100vh] h-[100%] bg-[white]">
      <UserLayout plan>
        {isPaymentOpen && (
          <Payment
            state={{
              plan: selectedPlan.id,
              duration: selectedPlan.duration,
            }}
            isOpen={isPaymentOpen}
            handleModal={() => {
              setIsPaymentOpen(!isPaymentOpen);
            }}
            refetch={refetch}
          />
        )}
        {/* {isOpen && ( */}
        <UpgradePlan
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSelectedPlan={setSelectedPlan}
          openPayment={() => {
            // setIsOpen(false);
            setIsPaymentOpen(true);
          }}
          refetch={refetch}
        />
        {/* )} */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.125 }}
        >
          <div
            className={`${
              isMobile ? "block" : "grid"
            } items-center justify-center content-center`}
          >
            {data && (
              <h1 className="title uppercase md:text-[2.75rem] text-[1.25rem] font-[500] md:text-center text-start md:mb-[2.625rem] mb-[1.5rem]">
                Your plan
              </h1>
            )}
            {!data && (
              <div className="h-[calc(100vh-200px)] flex flex-col justify-center">
                <div className="grid justify-items-center">
                  <span className="title mb-[1.25rem] text-center leading-[120%] text-[2.25rem] uppercase font-[500]">
                    No subscription found
                  </span>
                  <span className="mb-[2.75rem] text-[#31302E] text-[1.125rem] leading-[160%] max-w-[530px] text-center">
                    You do not have an active plan, please subscribe to a plan
                    by clicking the button below
                  </span>
                  <Button
                    className="py-[1.25rem] px-[2.25rem] w-fit text-[1rem] font-[500]"
                    onClick={() => setIsOpen(true)}
                  >
                    Get a subscription
                  </Button>
                </div>
              </div>
            )}
            {data && (
              <div>
                <PlanCard
                  id={data.id}
                  title={data.name}
                  price={{
                    monthly: data.priceMonthly,
                    half: data.priceHalfYearly,
                    yearly: data.priceYearly,
                  }}
                  features={data.benefits}
                  primary={data.textColor}
                  secondary={data.bgColor}
                  delay={0}
                  option={data.duration.toLowerCase()}
                  className="bg-[white] border-none w-[34.375rem] mx-auto pb-3 mb-8"
                  subscribedOn={data.subscribedOn}
                  nextCycle={data.nextCycle}
                  dashboard
                  setSelectedPlan={setSelectedPlan}
                />
                <Button
                  className="w-full mt-auto px-[1.75rem] md:py-[1.5rem] py-[1.25rem] font-[500] text-[1rem]"
                  onClick={() => setIsOpen(true)}
                >
                  Update your plan
                </Button>
              </div>
            )}
          </div>
        </motion.div>
      </UserLayout>
    </div>
  );
}
