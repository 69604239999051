import NBAIcon from "assets/images/table-nba.png";
import NCAABIcon from "assets/images/table-ncaab.png";
import ScrollToTop from "helpers/ScrollToTop";
import Menu from "components/Menu";
import { motion } from "framer-motion";
import { useQuery } from "react-query";
import selection from "helpers/api/selection";
import { sports } from "helpers/constant";
import moment from "moment";
import capitalize from "helpers/hooks/capitalize";

export default function PerformanceMobile() {
  const isLoggedIn = !!window.localStorage.getItem("token");
  const { data, isLoading } = useQuery(
    "performace",
    selection.getPublicSelections
  );

  return (
    <div
      className=""
      {...(!isLoggedIn
        ? {
            style: {
              backgroundImage: `url(${require("assets/images/hero.png")})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
              backgroundSize: "contain",
            },
          }
        : {})}
    >
      <ScrollToTop />
      <Menu type="landing" />
      <div className="grid pt-[5.5rem] gap-4 px-4 pb-[1rem]">
        <h1 className="title font-sans uppercase py-6 md:text-[2rem] text-[1.5rem] font-[500] text-center">
          Last 30 selections
        </h1>
        {isLoading &&
          // do skeleton 5 times
          Array(5)
            .fill(0)
            .map((_, i) => <CardSkeleton key={i} />)}

        {!isLoading &&
          data?.map((item: any, index: string) => (
            <Card key={index} data={item} />
          ))}
      </div>
    </div>
  );
}

function CardSkeleton() {
  return (
    <motion.div
      className="grid w-full rounded-[8px] p-4 gap-2 animate-pulse"
      initial={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.125 }}
    >
      <div className="flex flex-wrap gap-y-[1.25rem]">
        <div className="grid gap-2 w-2/3">
          <div className="w-24 h-4 bg-gray-300 rounded"></div>
          <div className="w-32 h-4 bg-gray-300 rounded"></div>
        </div>
        <div className="flex w-full gap-x-2">
          <div className="grid gap-2 w-1/3">
            <span className="w-20 h-4 bg-gray-300 rounded"></span>
            <span className="w-16 h-4 bg-gray-300 rounded"></span>
          </div>
          <div className="w-1/3 h-4 bg-gray-300 rounded"></div>
          <div className="w-1/3 h-4 bg-gray-300 rounded"></div>
        </div>
      </div>
    </motion.div>
  );
}

function Card({ data }: { data: any }) {
  return (
    <motion.div
      className="grid w-full bg-[white] rounded-[8px] p-4 gap-2"
      initial={{ opacity: 0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.125 }}
      viewport={{ once: true }}
    >
      <div className="flex flex-wrap gap-y-[1.25rem]">
        <div className="w-full">
          <div className="flex w-full mb-2">
            <div className="w-1/3 uppercase text-[#878787] text-[0.75rem] font-[500]">
              Sport
            </div>
            <div className="w-2/3 uppercase text-[#878787] text-[0.75rem] font-[500]">
              Team
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-1/3">
              <img
                src={sports.find((s) => s.name === data.teams[0].sports)?.icon}
                alt="sport"
                className="w-[1.5rem] md:w-[1.75rem] md:h-[1.75rem] inline-block mr-[0.375rem] self-baseline"
              />
              <span className="font-[500] text-[0.875rem]">
                {data.teams[0].sports}
              </span>
            </div>
            <div className="w-2/3">
              <div className="text-[0.875rem] font-[500] flex items-center gap-2">
                <img
                  src={data.teams[0].logo}
                  alt="team"
                  className="w-[1.5rem] rounded-full self-baseline"
                />
                <div>
                  <span>{data.teams[0].name} </span>
                  <br />
                  <span className="text-[0.875rem] text-[#666666]">
                    ({capitalize(data.teams[0]?.wager)})
                  </span>
                </div>
              </div>
            </div>
          </div>
          {data.type !== "I" && data.teams[1] && (
            <div className="flex w-full mt-2">
              <div className="w-1/3">
                <img
                  src={
                    sports.find((s) => s.name === data.teams[1]?.sports)?.icon
                  }
                  alt="sport"
                  className="w-[1.5rem] md:w-[1.75rem] md:h-[1.75rem] inline-block mr-[0.375rem] self-baseline"
                />
                <span className="font-[500] text-[0.875rem]">
                  {data.teams[1]?.sports}
                </span>
              </div>
              <div className="w-2/3">
                <div className="text-[0.875rem] font-[500] flex items-center gap-2">
                  <img
                    src={data.teams[1]?.logo}
                    alt="team"
                    className="w-[1.5rem] rounded-full self-baseline"
                  />
                  <div>
                    <span>{data.teams[1]?.name} </span>
                    <span className="text-[0.875rem] text-[#666666]">
                      ({capitalize(data.teams[1]?.wager)})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex w-full">
          {/* <TagText title="Sport" value={data.sport} /> */}
          <TagText title="Date" value={moment(data.day).format("MM/DD/YY")} />

          <TagText title="Prob %" value={data.winningProbability} />
          <TagText
            title="Result"
            value={
              data.winningStatus === 0
                ? "-"
                : data.winningStatus === 1
                ? "Win"
                : data.winningStatus === 2
                ? "Push"
                : "Loss"
            }
            win={data.winningStatus === 1}
            loss={data.winningStatus === -1}
          />
        </div>
      </div>
    </motion.div>
  );
}

function TagText({
  title,
  value,
  TwoThirds,
  win,
  loss,
}: {
  title: string;
  value: string;
  TwoThirds?: boolean;
  win?: boolean;
  loss?: boolean;
}) {
  return (
    <div className={`grid gap-2 ${TwoThirds ? "w-2/3" : "w-1/3"}`}>
      <span className="uppercase text-[#878787] text-[0.75rem] font-[500]">
        {title}
      </span>
      <span
        className={`text-[0.875rem] font-[500] ${win && "text-[#00AB11]"} ${
          loss && "text-[#EE3900]"
        }`}
        style={{
          // title==="Prob %" ? {color: parseInt(value) >= 70 ? "#00AB11" : "black"} : {}
          // if title is prob % and value is greater than or equal to 70, then color is green, else black
          color:
            title === "Prob %" && parseInt(value) >= 70 ? "#00AB11" : "black",
          fontWeight: title === "Prob %" && parseInt(value) >= 70 ? 700 : 500,
        }}
      >
        {title === "Prob %" ? value + "%" : value}
      </span>
    </div>
  );
}
