import Button from "components/Buttons";
import { motion } from "framer-motion";
import moment from "moment";
import { Link } from "react-router-dom";
const listIcon = require("assets/images/list-icon.svg");

export default function PlanCard({
  title,
  price,
  features,
  primary,
  secondary,
  option,
  delay,
  className,
  dashboard,
  subscribedOn,
  nextCycle,
  id,
  setSelectedPlan,
  openPayment,
}: {
  id: string;
  title: string;
  price:
    | {
        monthly?: string;
        half?: string;
        yearly?: string;
      }
    | string;
  features: string[];
  primary: string;
  secondary: string;
  option: "monthly" | "half_yearly" | "yearly";
  delay: number;
  className?: string;
  dashboard?: boolean;
  subscribedOn?: string;
  nextCycle?: string;
  setSelectedPlan?: any;
  openPayment?: any;
}) {
  return (
    <motion.div
      className={`${className} rounded-[22px] w-full relative pt-3 lg:h-[100%] ${
        dashboard ? "md:min-w-[550px]" : ""
      }`}
      initial={dashboard ? { opacity: 0 } : { opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: delay }}
      viewport={{ once: true }}
      exit={{ opacity: 0 }}
      style={{
        background: secondary,
      }}
    >
      <div className="grid md:h-[100%]">
        <div>
          <div
            className="md:p-[1.5rem] p-4 grid md:gap-3 gap-2 mx-3 md:mb-3 mb-[1.25rem] rounded-[12px] relative"
            style={{
              // border: `${primary} solid 1px`,
              background: "#ffffff99",
            }}
          >
            <span className="font-[700] uppercase leading-[120%] md:text-[1rem] text-[0.75rem]">
              {title}
            </span>
            <span className="md:text-[1.5rem] text-[1.25rem] font-[800] leading-[120%]">
              $
              {typeof price === "string"
                ? price
                : price[
                    option === "monthly"
                      ? "monthly"
                      : option === "half_yearly"
                      ? "half"
                      : "yearly"
                  ]}
              /
              {option === "monthly"
                ? "month"
                : option === "half_yearly"
                ? "6 months"
                : "year"}
            </span>
            <span className="leading-[120%] md:text-[1rem] text-[0.875rem]">
              {
                // if yearly option is selected, show the monthly price
                option === "yearly" ? (
                  <>
                    <span className="font-[600]">
                      $
                      {typeof price === "object"
                        ? parseInt(price.yearly || "") / 12
                        : ""}{" "}
                    </span>
                    per month on yearly plan
                  </>
                ) : (
                  <>
                    Save up to <span className="font-[600]">20%</span> on yearly
                    plan
                  </>
                )
              }
            </span>
            {dashboard && (
              <div className="bg-[white] md:py-[0.625rem] md:px-[0.875rem] py-[0.5rem] px-[0.625rem] absolute top-[1.5rem] right-[1.5rem] rounded-[6px]">
                <span className="font-[500] md:text-[1rem] text-[0.75rem]">
                  {option === "monthly"
                    ? "Monthly Plan"
                    : option === "half_yearly"
                    ? "Half Yearly Plan"
                    : "Yearly Plan"}
                </span>
              </div>
            )}
          </div>
          {dashboard && (
            <div className="grid md:gap-0 gap-2 md:flex justify-between px-[1.5rem] pt-[0.375rem] pb-[1.5rem] border-b-[#DADADA] border-b-solid border-b-[1px]">
              <span className="text-[#747474] font-[500] md:text-[1rem] text-[0.8125rem]">
                Started on:{" "}
                <span className="text-[black] tracking-[-0.48px]">
                  {moment(subscribedOn).format("MMM DD, YYYY")}
                </span>
              </span>
              <span className="text-[#747474] font-[500] md:text-[1rem] text-[0.8125rem]">
                Next billing on:{" "}
                <span className="text-[black] tracking-[-0.48px]">
                  {moment(nextCycle).format("MMM DD, YYYY")}
                </span>
              </span>
            </div>
          )}
          <div
            className={`${
              dashboard
                ? "md:pt-[1.25rem] pt-4 md:mb-[1.25rem] mb-2"
                : "pt-2 mb-[1.25rem]"
            } px-[1.75rem] grid gap-3`}
          >
            <ol className="md:gap-[1.25rem] gap-[0.75rem] grid">
              {features?.map((feature) => (
                <div className="flex items-base gap-2">
                  <div className="pt-[2px]">
                    <listIcon.ReactComponent className="w-4 h-[auto]" />
                  </div>
                  <li
                    className="md:text-[1.125rem] text-[0.875rem] flex items-center gap-2 leading-[120%] max-w-[500px]"
                    key={feature}
                  >
                    {feature}
                  </li>
                </div>
              ))}
            </ol>
          </div>
        </div>
        {!dashboard && (
          <div className="md:px-[1.75rem] md:pb-[1.75rem] px-[1rem] pb-[1rem] mt-auto">
            <Button
              className="w-full mt-auto px-[1.75rem] py-[0.875rem] font-[500] text-[1rem]"
              onClick={() => {
                window.localStorage.getItem("token")
                  ? openPayment()
                  : (window.location.href = "/login");
                setSelectedPlan({
                  id,
                  duration: option,
                });
              }}
            >
              Get started
            </Button>
          </div>
        )}
      </div>
    </motion.div>
  );
}
export const PlanCardSkeleton = ({
  className,
  dashboard,
}: {
  className?: string;
  dashboard?: boolean;
}) => {
  return (
    <div
      className={`${className} border-[#DBDADA] border-[1px] rounded-[22px] w-full relative pt-3 md:w-[400px] w-full ${
        dashboard ? "md:min-w-[550px]" : ""
      }`}
    >
      <div className="grid md:h-[100%]">
        <div>
          <div className="p-[1.5rem] grid gap-3 mx-3 mb-3 rounded-[12px] relative bg-gray-200 animate-pulse">
            <span className="font-[700] uppercase">&nbsp;</span>
            <span className="text-[1.5rem] font-[800]">&nbsp;</span>
            <span>&nbsp;</span>
            {dashboard && (
              <div className="bg-[white] py-[0.625rem] px-[0.875rem] absolute top-[1.5rem] right-[1.5rem] rounded-[6px]">
                <span className="font-[500] text-[1rem]">&nbsp;</span>
              </div>
            )}
          </div>
          {dashboard && (
            <div className="grid md:flex justify-between px-[1.5rem] pt-[0.375rem] pb-[1.5rem] border-b-[#DADADA] border-b-solid border-b-[1px] bg-gray-200 animate-pulse">
              <span className="text-[#747474] font-[500]">&nbsp;</span>
              <span className="text-[#747474] font-[500]">&nbsp;</span>
            </div>
          )}
          <div
            className={`${
              dashboard ? "pt-[1.25rem] mb-[1.25rem]" : "pt-4 mb-[2.25rem]"
            } px-[1.75rem] grid gap-3`}
          >
            <ol className="gap-[1.25rem] grid">
              {[...Array(3)].map((_, index) => (
                <li
                  className="text-[1.125rem] flex items-center gap-2 bg-gray-200 animate-pulse"
                  key={index}
                >
                  &nbsp;
                </li>
              ))}
            </ol>
          </div>
        </div>
        {!dashboard && (
          <div className="px-[1.75rem] pb-[1.75rem] mt-auto">
            <div className="w-full mt-auto px-[1.75rem] py-[0.875rem] font-[500] text-[1rem] bg-gray-200 animate-pulse rounded-md">
              &nbsp;
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
