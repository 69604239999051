/* eslint-disable import/no-anonymous-default-export */
import client from "./client";

export default {
  get: (date: string, page?: number, limit?: number) =>
    client
      .get(`/users/${localStorage.getItem("id")}/selections`, {
        params: {
          day: date,
          page: page || 0,
          limit: limit || 100,
          showPrevious: false,
        },
      })
      .then((res) => res.data),
  getPublicSelections: () =>
    client
      .get(`/selections`, {
        params: {
          page: 0,
          limit: 30,
          showPrevious: true,
        },
      })
      .then((res) => res.data),
};
