import { useState } from "react";
import { motion } from "framer-motion";
import Menu from "components/Menu";
import useResponsive from "helpers/useResponsive";
const plus = require("assets/images/plus.svg");
const minus = require("assets/images/minus.svg");

const QAs = [
  {
    question: "How Long Have You Been Around?",
    answer:
      "While We Pick U Play is a relatively new company, the algorithm that drives our selections has been in use for over 20 years. This algorithm has significantly grown and evolved with advancements in sports data analytics. Initially focusing on NFL, NBA, and MLB, we later expanded our analysis to include NCAAB and NCAAF. This continual development has enabled us to consistently deliver reliable results. Our long-standing experience in the industry, coupled with our proprietary algorithm, ensures that we provide well-established, time-tested selections.",
  },
  {
    question: "How Does Your System Work?",
    answer:
      "Our system employs a meticulous four-step process that ensures objectivity by removing human bias. Selections are made only when specific criteria are met, optimizing risk management for a higher probability of profit. While some days may not feature a primary selection, others might present multiple options, depending on the day's qualifying events.",
  },
  {
    question: "What Type of Selections Do You Offer?",
    answer:
      "Our algorithm generates a diverse range of betting options including moneylines, totals, spreads, parlays, and teasers, catering to a variety of sports.",
  },
  {
    question: "What Sports Are Covered?",
    answer: "We provide selections for NFL, MLB, NBA, NCAAB, and NCAAF.",
  },
  {
    question: "How Are the Selections Sent?",
    answer:
      'Daily selections are emailed and posted on your customer dashboard well in advance (at least 2 hours before game time), allowing for considerations such as player injuries. On days without qualifying games, a "No Selection for Today" notice will be sent. Each selection details the sport, team, wager type, win probability, and the date/time of the event.',
  },
  {
    question: "How to Use the Selections?",
    answer:
      "You're free to choose how to play the selections; whether focusing on the highest win probability or playing multiple suggestions. Our goal is to provide the day's best picks, leaving the ultimate decision to you.",
  },
  {
    question: "When Does My Subscription Start?",
    answer:
      "Your subscription starts immediately, but the timing of your first selection email depends on when you sign up. If you subscribe after the daily email dispatch, your first selections will arrive the following day.",
  },
  {
    question: "How Many Selections Per Day?",
    answer:
      "The number of selections you receive daily varies with the sports calendar and your subscription type. During peak seasons with overlapping sports, you may receive more selections. The quantity can range from one to five daily picks, with the possibility of no selections on certain days, adhering strictly to our algorithm's criteria.",
  },
  {
    question: "Pre-Season and Playoff Selections?",
    answer:
      "Our algorithm does not support pre-season games due to the lack of current season data. However, we do offer playoff selections, albeit fewer, as these games typically have closer statistics.",
  },
  {
    question: "Do You Offer Player Props & 5-Leg Parlays?",
    answer:
      "No, we do not offer selections beyond team outcomes such as player props. Our primary focus is on analyzing team data, which is rigorously tailored and time-tested through extensive data analysis. We limit our parlays/teasers to two-team combinations. Our strategy emphasizes quality and reliability, ensuring each selection enhances the overall effectiveness of our offerings.",
  },
  {
    question: "Can I Place Bets on Your Site?",
    answer:
      "No, our platform does not facilitate betting. We provide information derived from our proprietary algorithm to assist in your betting decisions. Please be aware that sports betting regulations can vary significantly based on your location. We advise you to consult your local gambling laws before placing any bets.",
  },
];
export default function FAQ() {
  const [active, setActive] = useState(-1);
  const location = window.location.href;
  const locationLastPart = location.split("/").pop();
  const { isTablet } = useResponsive();

  const handleOpen = (index: number) => {
    if (active === index) setActive(-1);
    else {
      setActive(index);
    }
  };

  return (
    <>
      {locationLastPart === "faq" && <Menu fixed type="landing" />}
      <section
        id="faq"
        className={`${
          locationLastPart === "faq"
            ? "md:mt-[0rem] md:pt-[8rem] mt-0 pb-[2rem] pt-[5.5rem]"
            : "md:mt-[12.5rem] mt-[5rem]"
        } grid justify-items-center`}
      >
        <motion.h3
          className={`${
            locationLastPart === "faq"
              ? "md:text-[3rem] text-[1.75rem] md:mb-[4rem]"
              : "title md:mb-[3rem] md:text-[3.125rem] text-[2.25rem] mb-0 "
          } font-monument uppercase font-[800]`}
          initial={
            locationLastPart === "faq" ? { opacity: 0 } : { opacity: 0, y: 50 }
          }
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          exit={{ opacity: 0 }}
        >
          FAQs
        </motion.h3>
        <motion.ol
          className="max-w-[800px] font-sans"
          initial={locationLastPart === "faq" && { opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          exit={{ opacity: 0 }}
        >
          {QAs.map((qa, index) => (
            <div
              className={`${
                index !== 10 && "border-b-[1px]"
              } border-b-[#CECBC3]`}
            >
              <button
                key={index}
                className={`overflow-hidden text-start w-full py-[1.625rem]`}
                onClick={() => handleOpen(index)}
              >
                <div className="w-full">
                  <li className="items-center md:px-[1.25rem] px-4 font-[500] md:text-[1.25rem] text-[0.875rem] leading-[160%] flex justify-between w-full text-left hover:opacity-[0.7] transition-all">
                    {qa.question}{" "}
                    <div>
                      {active === index && (
                        <img
                          src={minus.default}
                          alt="minus"
                          className="w-[20px] h-[20px]"
                        />
                      )}
                      {active !== index && (
                        <img
                          src={plus.default}
                          alt="plus"
                          className="w-[20px] h-[20px]"
                        />
                      )}
                    </div>
                  </li>
                </div>
              </button>
              <motion.div
                className="overflow-hidden"
                initial={active === index ? { height: "auto" } : { height: 0 }}
                animate={active === index ? { height: "auto" } : { height: 0 }}
                transition={{ duration: 0.25 }}
              >
                <div className="bg-[#DEF6D2] p-[1.25rem] rounded-[12px] mb-[1.625rem]">
                  <p className={`md:px-[1.25rem] px-[1rem] text-[0.875rem]`}>
                    {qa.answer.split("<br />").map((line, index) => (
                      // break at <br /> tag

                      <span
                        key={index}
                        className="leading-[160%] text-[#333333] md:text-[1.125rem]"
                      >
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
              </motion.div>
            </div>
          ))}
        </motion.ol>
      </section>
    </>
  );
}
