import ScrollToTop from "helpers/ScrollToTop";
import Footer from "../home/Footer";
import Menu from "components/Menu";
import { motion } from "framer-motion";
import selection from "helpers/api/selection";
import { useQuery } from "react-query";
import { sports } from "helpers/constant";
import moment from "moment";
import capitalize from "helpers/hooks/capitalize";

const SkeletonLoading = () => {
  return (
    <>
      {Array(20)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            className="flex p-[1.5rem] text-[1.125rem] font-[500] text-left"
          >
            <span className="w-1/6">
              <span className="animate-pulse bg-gray-300 h-5 w-20 block rounded-md"></span>
            </span>
            <span className="w-1/6">
              <span className="animate-pulse bg-gray-300 h-5 w-28 block rounded-md"></span>
            </span>
            <span className="w-1/3">
              <span className="animate-pulse bg-gray-300 h-5 w-48 block rounded-md"></span>
            </span>
            <span className="w-1/6">
              <span className="animate-pulse bg-gray-300 h-5 w-20 block rounded-md"></span>
            </span>
            <span className="w-1/6">
              <span className="animate-pulse bg-gray-300 h-5 w-20 block rounded-md"></span>
            </span>
          </div>
        ))}
    </>
  );
};

export default function PerformanceV2() {
  const isLoggedIn = !!window.localStorage.getItem("token");
  const { data, isLoading } = useQuery(
    "performace",
    selection.getPublicSelections
  );

  return (
    <body className={`${!isLoggedIn ? "bg-[white]" : "pb-[137px]"}`}>
      <ScrollToTop />
      <Menu type="landing" transparentTop />
      <motion.div
        className="md:pt-[9.5rem] pt-[8rem] grid text-center"
        {...(!isLoggedIn
          ? {
              style: {
                backgroundImage: `url(${require("assets/images/hero.png")})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
                backgroundSize: "100vw 70vh",
              },
            }
          : {})}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.125 }}
        viewport={{ once: true }}
      >
        <h1 className="title font-sans uppercase md:text-[3rem] text-[2rem] font-[500] mb-[4rem]">
          Last 30 selections
        </h1>
        <div className="grid bg-[white] rounded-[24px] w-full p-[0.75rem] max-w-[1100px] mx-auto shadow-lg">
          <div className="flex bg-[#DDEBDF] rounded-[12px] px-[1.5rem] py-[1.25rem] text-[1.125rem] font-[500] tracking-[-0.4px] text-left">
            <span className="w-1/6">DATE</span>
            <span className="w-1/6">SPORT</span>
            <span className="w-1/3">TEAM</span>
            <span className="w-1/6">PROB %</span>
            <span className="w-1/6">RESULT</span>
          </div>
          {isLoading && <SkeletonLoading />}
          {data?.map((item: any) => (
            <div
              key={item.id}
              className="flex p-[1.5rem] text-[1.125rem] font-[500] text-left border-b-solid border-b-[1px] border-b-[#c7c7c763]"
              style={{
                // last index no border
                borderBottom:
                  data.indexOf(item) === data.length - 1 ? "none" : "",
              }}
            >
              <span className="w-1/6 grid items-center">
                {moment(item.day, "YYYY-MM-DD").format("MM/DD/YY")}
              </span>
              <div className="w-1/6">
                <div className="grid gap-2">
                  <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
                    <img
                      src={
                        sports.find((s) => s.name === item.teams[0].sports)
                          ?.icon
                      }
                      alt="NBA"
                      className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
                    />
                    <span className="">{item.teams[0].sports}</span>
                  </div>
                  {item.type !== "I" && item.teams[1] && (
                    <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
                      <img
                        src={
                          sports.find((s) => s.name === item.teams[1]?.sports)
                            ?.icon
                        }
                        alt="NBA"
                        className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
                      />
                      <span className="">{item.teams[1]?.sports}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-1/3 grid gap-2 items-center">
                <span className="font-[500]">
                  {item.teams[0]?.name}{" "}
                  <span className="text-[1rem] text-[#666666]">
                    ({capitalize(item.teams[0]?.wager)})
                  </span>
                </span>
                {item.type !== "I" && item.teams[1] && (
                  <span className="font-[500]">
                    {item.teams[1]?.name}{" "}
                    <span className="text-[1rem] text-[#666666]">
                      ({capitalize(item.teams[1]?.wager)})
                    </span>
                  </span>
                )}
              </div>
              <span className="w-1/6 items-center grid">
                {
                  // parse int
                  parseInt(item.winningProbability) >= 70 ? (
                    <span className="text-[#00AB11] font-[700]">
                      {item.winningProbability}%
                    </span>
                  ) : (
                    <span>{item.winningProbability}%</span>
                  )
                }
              </span>
              <span
                className={`w-1/6 items-center grid`}
                style={{
                  // win, push, lose, no result
                  color:
                    item.winningStatus === 0
                      ? "black"
                      : item.winningStatus === 1
                      ? "#00AB11"
                      : item.winningStatus === 2
                      ? "#FFA800"
                      : "#EE3900",
                }}
              >
                {item.winningStatus === 0 && "-"}
                {item.winningStatus !== 0 &&
                  (item.winningStatus === 1
                    ? "Win"
                    : item.winningStatus === 2
                    ? "Push"
                    : "Loss")}
              </span>
            </div>
          ))}
        </div>
      </motion.div>
      {!isLoggedIn && <Footer />}
    </body>
  );
}
