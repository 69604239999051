import { useAuth } from "helpers/contexts/AuthContext";
import { Navigate, useLocation } from "react-router-dom";

export function ProtectedRoute({ children }: any) {
  const { getToken } = useAuth();
  const location = useLocation();
  if (!getToken() || getToken() === null || getToken() === undefined) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}
