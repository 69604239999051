import useResponsive from "helpers/useResponsive";
import { motion } from "framer-motion";

const register = require("assets/images/register.svg");
const risk = require("assets/images/risk.svg");
const win = require("assets/images/win.svg");
const lineDots = require("assets/images/line-dots.svg");

export default function Product() {
  const { isMobile } = useResponsive();
  return (
    <section
      id="product"
      className="grid md:gap-[5rem] gap-12 justify-items-center md:mt-[11.25rem] my-[5rem] px-4"
    >
      <h3 className="title uppercase md:text-[3rem] text-[2.25rem] font-[500] text-center">
        Our Product Is Easy
      </h3>
      <div className="md:flex lg:gap-[5rem] md:gap-8 gap-8 relative grid">
        {!isMobile && (
          <motion.div
            className="absolute z-[1] top-[2rem] w-full overflow-hidden"
            initial={{
              // use this to reveal from left by changing the width
              opacity: 0,
            }}
            whileInView={{
              opacity: 1,
            }}
            transition={{ duration: 1, delay: 0.75 }}
            viewport={{ once: true }}
          >
            <lineDots.ReactComponent className="w-full" />
          </motion.div>
        )}
        <Card
          title="Register"
          description="The customer signs up and notifications begin daily, which include either selections qualified by the algorithm or a notification that the algorithm did not qualify a selection."
          delay={0}
        />
        <Card
          title="Wager Placement"
          description="After receiving the algorithm's selections, it's up to the customer to place their wager(s) through their betting platform of choice."
          delay={0.25}
        />
        <Card
          title="Enjoy the Game"
          description="Once the wagers are placed, the customer can sit back and watch the game, knowing they have the highest probability to win."
          delay={0.5}
        />
      </div>
    </section>
  );
}

function Card({
  title,
  description,
  delay,
}: {
  title?: string;
  description?: string;
  delay: number;
}) {
  return (
    <motion.div
      className="grid text-center content-baseline"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      viewport={{ once: true }}
    >
      {title === "Register" && (
        <register.ReactComponent className="w-[6.25rem] h-[6.25rem] mx-auto z-[2]" />
      )}
      {title === "Wager Placement" && (
        <risk.ReactComponent className="w-[6.25rem] h-[6.25rem] mx-auto z-[2]" />
      )}
      {title === "Enjoy the Game" && (
        <win.ReactComponent className="w-[6.25rem] h-[6.25rem] mx-auto z-[2]" />
      )}
      <span className="font-sans text-[1.75rem] font-[700] tracking-[-0.54px] md:mt-[3rem] mt-4">
        {title}
      </span>
      <p className="font-sans font-[400] max-w-[320px] mt-[0.625rem]">
        {description}
      </p>
    </motion.div>
  );
}
