export default function Footer({ className }: { className?: string }) {
  return (
    <footer
      className={`${className} absolute bottom-6 mx-auto flex items-center justify-center`}
    >
      <span className="font-[500] text-[0.875rem] md:text-[1rem]">
        © 2024 we pick u play. All Rights Reserved.
      </span>
    </footer>
  );
}
