import Button from "components/Buttons";
import Modal from "components/Modal";
import styled from "styled-components";

export default function LogoutPopup({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: () => void;
}) {
  const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("id");
    window.location.reload();
  };
  return (
    <Modal noTitle isOpen={isOpen} handleModal={handleModal}>
      <h1 className="text-[1.25rem] font-[600] text-[#33363F] text-center">
        Are you sure you want to logout?
      </h1>
      <div className="flex gap-3 mt-[1.5rem] justify-center w-full">
        <CustomButton className="w-1/2" onClick={handleModal}>
          <button className="text-[1rem] font-[500] px-[1.75rem] py-[0.875rem]">
            Go back
          </button>
        </CustomButton>
        <Button
          className="text-[1rem] font-[500] px-[1.75rem] py-[0.875rem] w-1/2"
          onClick={logout}
        >
          Yes, logout
        </Button>
      </div>
    </Modal>
  );
}

const CustomButton = styled.button`
  border: solid 1px transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #62df47, #aef3a0);
  background-origin: border-box;
  background-clip: content-box, border-box;
  /* on hover animate to background image */
  transition: all 0.3s ease;
  &:hover {
    background-image: linear-gradient(white, white),
      linear-gradient(to bottom left, #62df47, #aef3a0);
  }
`;
