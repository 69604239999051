import Button from "components/Buttons";
import Modal from "components/Modal";
import PlanCard from "components/PlanCard";
import { motion } from "framer-motion";
import { subscription } from "helpers/api";
import { useAuth } from "helpers/contexts/AuthContext";
import useResponsive from "helpers/useResponsive";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
const closeButton = require("assets/images/x.svg");

export default function UpgradePlan({
  isOpen,
  setIsOpen,
  setSelectedPlan,
  openPayment,
  refetch,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setSelectedPlan: any;
  openPayment: () => void;
  refetch: () => void;
}) {
  const [option, setOption] = useState(
    "monthly" as "monthly" | "half_yearly" | "yearly"
  );
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const { data, isLoading } = useQuery("subscriptions", subscription.get);
  const handleFeedbackModal = () => {
    setIsFeedbackOpen(!isFeedbackOpen);
  };
  console.log(user);
  function Loading() {
    return (
      <>
        {/* Close Button Placeholder */}
        <button
          className="absolute top-0 right-0 z-[102] bg-[white] p-[1.25rem] rounded-full"
          onClick={() => setIsOpen(false)}
        >
          <closeButton.ReactComponent />
        </button>

        {/* Title Placeholder */}
        <h2 className="title md:text-[3.75rem] text-[1.75rem] font-[500] mt-[3rem] md:mt-[6rem] text-center uppercase">
          Update your plan
        </h2>

        {/* Plan Duration Options */}
        <motion.div
          className="p-[0.25rem] rounded-[70px] mb-6 md:mb-[2.5rem] bg-[#E3F0E7] flex gap-1 w-fit mx-auto mt-8 md:mt-[3.125rem]"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: isMobile ? 0 : 0.125 }}
          viewport={{ once: true }}
        >
          <button
            className={`px-4 md:px-[1.75rem] py-[0.875rem] rounded-[50px]
           ${
             option === "monthly"
               ? "bg-[white] text-[black]"
               : "text-[#6A6A6A] hover:bg-[#ffffff80]"
           } font-[500] text-sm md:text-[1.125rem] hover:text-[black] transition-all`}
            onClick={() => setOption("monthly")}
          >
            Monthly {!isMobile && "Plan"}
          </button>
          <button
            className={`${
              option === "half_yearly"
                ? "bg-[white] text-[black]"
                : "text-[#6A6A6A] hover:bg-[#ffffff80]"
            } px-[1.75rem] py-[0.875rem] rounded-[50px] text-[1.125rem] font-[500] hover:text-[black] transition-all`}
            onClick={() => setOption("half_yearly")}
          >
            Half Yearly {!isMobile && "Plan"}
          </button>
          <button
            className={`
          ${
            option === "yearly"
              ? "bg-[white] text-[black]"
              : "text-[#6A6A6A] hover:bg-[#ffffff80]"
          } 
          px-[1.75rem] py-[0.875rem] rounded-[50px] text-[1.125rem] font-[500] hover:text-[black] transition-all`}
            onClick={() => setOption("yearly")}
          >
            Yearly {!isMobile && "Plan"}
          </button>
        </motion.div>

        {/* Plan Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-8 max-w-[1100px] mx-auto">
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className="h-40 bg-gray-300 rounded-lg p-4 space-y-2"
            >
              {/* Simulate text lines and buttons inside each card */}
              <div className="h-4 bg-gray-200 rounded"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              <div className="h-6 bg-gray-200 rounded w-3/4"></div>
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <div>
      {/* close button */}
      <FeedbackModal
        isOpen={isFeedbackOpen}
        handleModal={handleFeedbackModal}
        refetch={refetch}
        setIsOpen={setIsOpen}
      />

      <motion.div
        className="bg-[white] fixed bottom-0 left-0 w-full z-[101] overflow-scroll"
        initial={{ height: 0 }}
        animate={{ height: isOpen ? (isMobile ? "94%" : "96%") : 0 }}
        exit={{ height: 0 }}
        transition={{ duration: 0.3 }}
      >
        {/* {isLoading && <Loading />} */}

        <div className="p-2">
          <button
            className="absolute top-0 right-0 z-[102] bg-[white] md:p-[1.25rem] p-4 rounded-full"
            onClick={() => setIsOpen(false)}
          >
            <closeButton.ReactComponent />
          </button>
          <h2 className="title md:text-[3.75rem] text-[1.75rem] font-[500] mt-[3rem] md:mt-[6rem] text-center uppercase">
            Update your plan
          </h2>
          <motion.div
            className="w-full md:w-fit p-[0.25rem] rounded-[70px] mb-6 md:mb-[2.5rem] bg-[#E3F0E7] flex gap-1 w-fit mx-auto mt-8 md:mt-[3.125rem]"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: isMobile ? 0 : 0.125 }}
            viewport={{ once: true }}
          >
            <button
              className={`md:w-fit w-1/3 px-4 md:px-[1.75rem] py-[0.875rem] rounded-[50px]
             ${
               option === "monthly"
                 ? "bg-[white] text-[black]"
                 : "text-[#6A6A6A] hover:bg-[#ffffff80]"
             }  px-4 md:px-[1.75rem] py-[0.875rem] rounded-[50px] md:text-[1.125rem] font-[500] hover:text-[black] transition-all`}
              onClick={() => setOption("monthly")}
            >
              Monthly {!isMobile && "Plan"}
            </button>
            <button
              className={`md:w-fit w-1/3 ${
                option === "half_yearly"
                  ? "bg-[white] text-[black]"
                  : "text-[#6A6A6A] hover:bg-[#ffffff80]"
              } px-4 md:px-[1.75rem] py-[0.875rem] rounded-[50px] md:text-[1.125rem] font-[500] hover:text-[black] transition-all`}
              onClick={() => setOption("half_yearly")}
            >
              Half Yearly {!isMobile && "Plan"}
            </button>
            <button
              className={`md:w-fit w-1/3 
            ${
              option === "yearly"
                ? "bg-[white] text-[black]"
                : "text-[#6A6A6A] hover:bg-[#ffffff80]"
            } 
            px-4 md:px-[1.75rem] py-[0.875rem] rounded-[50px] md:text-[1.125rem] font-[500] hover:text-[black] transition-all`}
              onClick={() => setOption("yearly")}
            >
              Yearly {!isMobile && "Plan"}
            </button>
          </motion.div>
          <div className="flex gap-[1rem] w-full flex-wrap md:px-4 lg:flex-nowrap max-w-[1100px] w-full mx-auto justify-center">
            <div className="grid gap-4 md:w-[auto] w-full">
              <div className="flex flex-col lg:flex-row gap-[1rem] w-full flex-wrap md:px-4 lg:flex-nowrap max-w-[1100px] justify-center">
                {data?.slice(0, 3).map?.((plan: any) => (
                  <div
                    className="flex-1 lg:flex-[none] lg:w-[calc(33%-0.5rem)] xl:min-w-[350px]"
                    key={plan.id}
                  >
                    <PlanCard
                      id={plan?.id}
                      key={plan?.id}
                      title={plan?.name}
                      price={{
                        monthly: plan?.priceMonthly,
                        half: plan?.priceHalfYearly,
                        yearly: plan?.priceYearly,
                      }}
                      features={plan?.benefits?.split?.("$$")}
                      primary={plan?.textColor}
                      secondary={plan?.bgColor}
                      delay={0}
                      option={option}
                      setSelectedPlan={setSelectedPlan}
                      openPayment={openPayment}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-col lg:flex-row gap-[1rem] w-full flex-wrap md:px-4 lg:flex-nowrap max-w-[1100px] justify-center">
                {data &&
                  data?.slice(3, 6).map?.((plan: any) => (
                    <div
                      className="flex-1 lg:flex-[none] lg:w-[calc(33%-0.5rem)] xl:min-w-[350px]"
                      key={plan.id}
                    >
                      <PlanCard
                        id={plan?.id}
                        key={plan?.id}
                        title={plan?.name}
                        price={{
                          monthly: plan?.priceMonthly,
                          half: plan?.priceHalfYearly,
                          yearly: plan?.priceYearly,
                        }}
                        features={plan?.benefits?.split?.("$$")}
                        primary={plan?.textColor}
                        secondary={plan?.bgColor}
                        delay={0}
                        option={option}
                        setSelectedPlan={setSelectedPlan}
                        openPayment={openPayment}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {
            // Only show cancel subscription button if user has a subscription
            user?.subscriptionId && (
              <div className="text-center w-full relative mt-[3rem] mb-4">
                <button
                  className="text-[#5F5F5F] font-[500]"
                  onClick={handleFeedbackModal}
                >
                  Cancel subscription
                </button>
              </div>
            )
          }
        </div>
      </motion.div>
      {isOpen && (
        <button
          className="bg-[rgba(0,0,0,0.5)] absolute top-0 left-0 w-full h-full z-[100]"
          onClick={() => setIsOpen(false)}
        ></button>
      )}
    </div>
  );
}

function FeedbackModal({
  isOpen,
  handleModal,
  refetch,
  setIsOpen,
}: {
  isOpen: boolean;
  handleModal: () => void;
  refetch: () => void;
  setIsOpen: (value: boolean) => void;
}) {
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { refetchUser } = useAuth();

  return (
    <Modal
      isOpen={isOpen}
      handleModal={handleModal}
      title="Feedback"
      // width="600px"
      zIndex="200"
      noAnimation
    >
      <div className="grid gap-4">
        <div className="flex items-center">
          <Wrapper>
            <label
              htmlFor="expensive"
              className="flex items-center gap-1 container"
            >
              <input
                type="checkbox"
                id="expensive"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                value="To expensive"
                checked={reason === "To expensive"}
                className="w-[1.375rem] h-[1.375rem] stroke-[#8D8E92]"
              />
              <span className="checkmark"></span>To expensive
            </label>
          </Wrapper>
        </div>
        <div className="flex items-center">
          <Wrapper>
            <label
              htmlFor="not-expected"
              className="flex items-center gap-1 container"
            >
              <input
                type="checkbox"
                id="not-expected"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                value="Not what I expected"
                checked={reason === "Not what I expected"}
                className="w-[1.375rem] h-[1.375rem] stroke-[#8D8E92]"
              />
              <span className="checkmark"></span>Not what I expected
            </label>
          </Wrapper>
        </div>
        <div className="flex items-center">
          <Wrapper>
            <label
              htmlFor="not-using"
              className="flex items-center gap-1 container"
            >
              <input
                type="checkbox"
                id="not-using"
                value="Not using anymore"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                checked={reason === "Not using anymore"}
                className="w-[1.375rem] h-[1.375rem] stroke-[#8D8E92]"
              />
              <span className="checkmark"></span>Not using anymore
            </label>
          </Wrapper>
        </div>
        <div className="flex items-center">
          <Wrapper>
            <label
              htmlFor="other"
              className="flex items-center gap-1 container"
            >
              <input
                type="checkbox"
                id="other"
                value="Other - with text box"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                checked={reason === "Other - with text box"}
                className="w-[1.375rem] h-[1.375rem] stroke-[#8D8E92]"
              />
              <span className="checkmark"></span>Other - with text box
            </label>
          </Wrapper>
        </div>
        {reason === "Other - with text box" && (
          <textarea
            className="min-h-[160px] border-[1px] border-[#E3E3E3] rounded-[0.875rem] px-[1.5rem] py-4 text-[black] font-[400] text-[1.125rem] placeholder-[#666666] placeholder-[1.125rem] h-fit resize-none focus:outline-none"
            placeholder="Message"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        )}
      </div>

      <Button
        variant="primary"
        className="mt-[1.875rem] w-full font-[500] text-[1rem] !py-4"
        isLoading={isLoading}
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          subscription
            .deleteSubscription(
              reason === "Other - with text box"
                ? { reason: message }
                : { reason }
            )
            .then(() => {
              setIsLoading(false);
              refetch();
              handleModal();
              refetchUser();
              console.log("Subscription cancelled");
            });
          setIsOpen(false);
        }}
      >
        Cancel subscription
      </Button>
    </Modal>
  );
}

const Wrapper = styled.div`
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.375rem;
    width: 1.375rem;
    background-color: transparent;
    border: 1px #a4a5a8 solid;
    border-radius: 4px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #73c947;
    border: 1px solid #73c947;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
