import Button from "components/Buttons";
import Input from "components/Input";
import Menu from "components/Menu";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import { useAuth } from "helpers/contexts/AuthContext";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useResponsive from "helpers/useResponsive";
const eyeSlashIcon = require("assets/images/eye-slash.svg");
const eyeIcon = require("assets/images/eye.svg");

export default function Login() {
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const { isDesktop } = useResponsive();

  const schema = yup
    .object({
      email: yup.string().email("Invalid email").required("Email is required"),
      password: yup.string().required("Password is required"),
    })
    .required();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);
    login(data, setIsLoading);
  };

  const { errors, isValid } = formState;

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="grid content-center justify-items-center">
      <Menu type="login" />

      <motion.div
        className="px-4 w-full flex justify-center items-center sm-height:h-auto h-[calc(100vh-80px)] pt-24"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.125 }}
        viewport={{ once: true }}
        exit={{ opacity: 0 }}
      >
        <div className="bg-[#FCFBFD] max-w-[28.125rem] md:min-w-[450px] w-full rounded-[24px] p-[2.5rem] h-fit">
          <h1 className="!text-[1.5rem] title font-[500] mb-[1.5rem] uppercase">
            Login
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <Input
                id="email"
                placeholder="Enter email address"
                label="Email"
                tabIndex={1}
                error={errors.email?.message}
                register={register}
              />
              <div className="relative">
                <Input
                  id="password"
                  placeholder="Enter password"
                  label="Password"
                  type={passwordShown ? "text" : "password"}
                  tabIndex={2}
                  error={errors.password?.message}
                  register={register}
                  isPasswordField
                  className="pr-12"
                />
                <button
                  className="absolute right-[1.125rem] top-[3rem]"
                  type="button"
                  onClick={() => setPasswordShown(!passwordShown)}
                  tabIndex={-1}
                >
                  <img
                    src={passwordShown ? eyeIcon.default : eyeSlashIcon.default}
                    alt="eye"
                  />
                </button>
              </div>
            </div>
            <Link
              to="/forgot-password"
              className="font-[500] text-[0.875rem] float-end transition-all hover:scale-[1.01] mt-[0.75rem] outline-none focus:!outline-primary"
            >
              Forgot password?
            </Link>
            <Button
              className="w-full py-[0.875rem] text-4 mt-6"
              type="submit"
              isLoading={isLoading}
              disabled={!isValid || isLoading}
            >
              Login
            </Button>
          </form>
          <div className="text-center mt-6">
            <span className="text-[0.875rem] font-[500]">
              Don’t have an account?{" "}
            </span>
            <Link
              className="text-[0.875rem] font-[600] text-primary opacity-[0.8] transition-all hover:opacity-100 hover:scale-[1.01] outline-none focus:!outline-primary"
              to="/register"
            >
              Sign up
            </Link>
          </div>
        </div>
      </motion.div>
      {isDesktop && <Footer />}
    </div>
  );
}
