import Button from "components/Buttons";
import Input from "components/Input";
import Modal from "components/Modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { user } from "helpers/api";
import { useQuery, useQueryClient } from "react-query";
import Spinner from "components/Spinner";
import { motion } from "framer-motion";
import axios from "axios";
import EditPassword from "./EditPasswordForm";
import toast from "react-hot-toast";
const uploadIcon = require("assets/images/upload.svg");
const uploadWhiteIcon = require("assets/images/upload-white.svg");
const passwordIcon = require("assets/images/password.svg");

export default function EditProfile({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: (value: boolean) => void;
}) {
  const queryCache = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery("user", user.get);
  const [imageUploading, setImageUploading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [tempImg, setTempImg] = useState<File>();
  const [imgUrl, setImgUrl] = useState("");
  const [view, setView] = useState("profile");
  const handlePasswordModal = () => {
    setView("password");
  };

  const schema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    mobile: yup
      .string()
      .required("Phone number is required")
      .length(10)
      .matches(/^[0-9]{10}$/, "Invalid mobile number"),
  });
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageUploading(true);
    user
      .getImageSignedURL(e.target?.files?.[0]?.type)
      .then((res) => {
        axios
          .put(
            res.uploadUrl,
            // remove data:image/png;base64, from the base64 string
            //   (reader.result as string)?.split(";")[1]
            e.target?.files?.[0],
            { headers: { "Content-Type": e?.target?.files?.[0].type } }
          )
          .then(() => {
            setImgUrl(res.accessUrl);
            // setTempImg so it can be shown in the UI
            setTempImg(e?.target?.files?.[0]);
            setImageUploading(false);
            // refetch();
          })
          .catch((err) => {
            console.log(err);
            setImgUrl("");
            setImageUploading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setImageUploading(false);
      });
  };

  const handleEditProfile = (formData: {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
  }) => {
    setIsSubmitLoading(true);
    user
      .put({
        ...formData,
        profileImg: imgUrl,
      })
      .then(async () => {
        await queryCache.invalidateQueries();
        refetch();
        setIsSubmitLoading(false);
        handleModal(false);
        toast.success("Profile updated successfully");
      });
  };

  useEffect(() => {
    if (data) {
      reset(data);
      setImgUrl(data.profileImg);
    }
  }, [data, reset]);

  return (
    <Modal
      isOpen={isOpen}
      handleModal={() => {
        handleModal(!isOpen);
        // setView profile after 0.5s
        setTimeout(() => {
          setView("profile");
        }, 500);
      }}
      secondaryTitle={
        !isLoading &&
        !isError &&
        view === "profile" && (
          <button
            className="flex gap-1 cursor-pointer"
            onClick={handlePasswordModal}
          >
            <passwordIcon.ReactComponent />
            <span className="font-[400] text-[1rem]">Change password</span>
          </button>
        )
      }
      hasBack={view === "password"}
      handleBack={() => setView("profile")}
      title={view === "profile" ? "Edit Profile" : "Change Password"}
    >
      {(isLoading || isError) && <Spinner />}
      {!isLoading && !isError && data && view === "profile" && (
        <motion.div
          exit={{ display: "none" }}
          transition={{ delay: 0.125, duration: 0.125 }}
        >
          <motion.form
            onSubmit={handleSubmit(handleEditProfile)}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.125 }}
          >
            <div className="flex mb-[1.875rem] gap-[1.5rem]">
              <label htmlFor="profileImg" className="grid gap-3">
                <div className="flex items-center relative rounded-full w-[6.25rem] h-[6.25rem] gap-[0.75rem]">
                  <input
                    type="file"
                    onChange={handleUpload}
                    id="profileImg"
                    className="hidden"
                    accept="image/png, image/jpeg"
                  />
                  {imageUploading && (
                    <div className="absolute w-full h-full bg-[black]/50 rounded-full">
                      <Spinner />
                    </div>
                  )}
                  <img
                    src={
                      (tempImg && URL.createObjectURL(tempImg)) ||
                      imgUrl ||
                      uploadIcon.default
                    }
                    alt="upload"
                    className="cursor-pointer h-full w-full rounded-full object-contain"
                  />
                  {/* have an overlay */}
                  {
                    // if there is no image, show the upload icon
                    (tempImg || imgUrl) && (
                      <>
                        <div className="absolute w-full h-full bg-[black]/40 rounded-full"></div>
                        <img
                          src={uploadWhiteIcon.default}
                          alt="upload"
                          className="cursor-pointer w-[1.75rem] h-[1.75rem] rounded-full object-cover absolute z-[0]"
                          style={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </>
                    )
                  }
                </div>
              </label>
              <div className="grid content-center gap-2">
                <button
                  className="text-[0.875rem] font-[500] text-[#F01818]"
                  type="button"
                  onClick={() => {
                    setImgUrl("");
                    setTempImg(undefined);
                  }}
                >
                  Remove photo
                </button>
              </div>
            </div>
            <div className="grid gap-[1.5rem]">
              <div className="flex gap-3">
                <Input
                  label="First name"
                  id="firstName"
                  register={register}
                  error={formState.errors.firstName?.message}
                />
                <Input
                  label="Last name"
                  id="lastName"
                  register={register}
                  error={formState.errors.lastName?.message}
                />
              </div>
              <Input
                label="Email"
                id="email"
                register={register}
                error={formState.errors.email?.message}
              />
              <Input
                label="Phone number"
                id="mobile"
                register={register}
                error={formState.errors.mobile?.message}
              />
              <Button
                className="w-full py-[0.875rem]"
                disabled={!formState.isValid || isSubmitLoading}
                isLoading={isSubmitLoading}
                type="submit"
              >
                Save
              </Button>
            </div>
          </motion.form>
        </motion.div>
      )}
      {view === "password" && <EditPassword />}
    </Modal>
  );
}
