import Menu from "components/Menu";
import useResponsive from "helpers/useResponsive";

export default function UserLayout({
  children,
  className,
  plan,
}: {
  children: React.ReactNode;
  className?: string;
  plan?: boolean;
}) {
  const { isMobile } = useResponsive();
  return (
    <div className={className}>
      <Menu type={plan ? "landing" : "user-dashboard"} transparentTop />
      <div
        className={`${
          plan && "!mt-0 md:!pt-[9.5rem] !pt-24"
        } mt-[1.5rem] mb-[2.5rem] ${
          isMobile ? "mx-4" : "mx-[6.25rem]"
        }  md:mt-16`}
      >
        {children}
      </div>
    </div>
  );
}
