import Button from "components/Buttons";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
// const SuccessView = require("assets/images/success.svg");
import Successful from "assets/lottie/Successful.json";

function Success({
  onClose,
  title,
  description,
  button,
  onClick,
  subFooter,
}: {
  onClose: () => void;
  title: string;
  description: string;
  button: string;
  onClick?: () => void;
  subFooter?: any;
}) {
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    navigate("/login");
    onClose();
  }, [navigate]);

  return (
    <div className="flex flex-col justify-center items-center">
      {/* <div className="w-[156px] h-[149px]">
        <SuccessView.ReactComponent />
      </div> */}
      <Lottie
        animationData={Successful}
        loop={false}
        initialSegment={[0, 37]}
        style={{
          height: "149px",
          // width: "auto",
        }}
      />
      <h1 className="uppercase font-monument font-[800] text-[1.5rem] text-black mt-[1.625rem]">
        {title}
      </h1>
      <div className="mt-2 mb-[26px]">
        <span className="text-base text-black leading-[160%] text-[1rem]">
          {description}
        </span>
      </div>
      <Button
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            logout();
          }
        }}
        className="px-[1.75rem] py-[0.875rem] font-[500] text-[1rem]"
      >
        {button}
      </Button>
      {subFooter && (
        <span className="text-[1rem] font-[400] leading-[160%] mt-[1.625rem]">
          {subFooter}
        </span>
      )}
    </div>
  );
}

export default Success;
