import Menu from "components/Menu";
import ScrollToTop from "helpers/ScrollToTop";
import Footer from "../home/Footer";
import { Link } from "react-router-dom";

export default function ResponsibleGaming() {
  return (
    <div className="bg-[white]">
      <ScrollToTop />
      <Menu type="landing" transparentTop />
      <div className="pt-[8rem] px-[1rem] max-w-[1000px] mx-auto">
        <h3 className="title uppercase md:text-[3rem] text-[2.25rem] font-[500] text-center mb-[4rem]">
          Responsible Gaming
        </h3>
        <h4>
          <strong>Control in Gambling</strong>
        </h4>
        <p>
          Regular gambling can be a harmless leisure activity or even a
          professional endeavor if done responsibly. Successful gamblers manage
          both time and money to prevent gambling from interfering with their
          lives, always avoiding bets they cannot afford to lose. Conversely,
          some individuals struggle with recognizing when to stop, often
          gambling more than intended and hoping for a significant win to offset
          their losses. This pattern, if sustained, may lead to compulsive
          gambling—a serious condition where gambling adversely affects one’s
          life. Symptoms of compulsive gambling include persistent gambling,
          neglecting responsibilities, mood fluctuations, and social withdrawal.
        </p>
        <br />
        <h4>
          <strong>The "Prove-It" Test</strong>
        </h4>
        <p>
          The excitement of sports betting can be captivating, but it is crucial
          to periodically assess one’s gambling behavior. A practical approach
          is to document gambling activities, reflecting on the time and money
          spent, and evaluating if these are detracting from other life aspects.
          Signs of potential addiction include the inability to stop gambling
          and rationalizing continued gambling as a means to recover losses.
        </p>
        <br />
        <h4>
          <strong>Seeking Help</strong>
        </h4>
        <p>
          Acknowledging a gambling issue is commendable, as vulnerability to
          addiction varies among individuals. If you suspect you are developing
          a compulsive gambling habit, it is advisable to consult with a
          healthcare provider or a community organization immediately.
          Alternatively, Gamblers Anonymous offers support through shared
          experiences, providing a platform for recovery.
        </p>
        <br />
        <h4>
          <strong>Legal Gambling Ages and Resources</strong>
        </h4>
        <p>
          In the USA, the legal gambling age is generally 21, with some states
          allowing it at 18. Useful resources include:
          <br />
          <ul>
            <li>
              National Council on Problem Gaming (
              <Link
                to="https://www.ncpgambling.org/"
                target="_blank"
                className="underline text-primary"
              >
                NCPG
              </Link>
              )
            </li>
            <li>
              National Center for Responsible Gaming (
              <Link
                to="https://www.icrg.org/"
                target="_blank"
                className="underline text-primary"
              >
                NCRG
              </Link>
              )
            </li>
            <li>
              Gamblers Anonymous (
              <Link
                to="https://www.gamblersanonymous.org/ga/"
                target="_blank"
                className="underline text-primary"
              >
                Gamblers Anonymous
              </Link>
              )
            </li>
            <li>
              Gambling Therapy (
              <Link
                to="https://www.gamblingtherapy.org/"
                target="_blank"
                className="underline text-primary"
              >
                Gambling Therapy
              </Link>
              )
            </li>
          </ul>
          <br />
          In Canada, the legal age varies between 18 and 19, depending on the
          province. Canadian resources include:
          <br />
          <ul>
            <li>
              Gamblers Anonymous (
              <Link
                to="https://www.gamblersanonymous.org/ga/"
                target="_blank"
                className="underline text-primary"
              >
                Gamblers Anonymous
              </Link>
              )
            </li>
            <li>
              Responsible Gambling Council (
              <Link
                to="http://www.cprg.ca/"
                target="_blank"
                className="underline text-primary"
              >
                RGC
              </Link>
              )
            </li>
            <li>
              Canadian Partnership for Responsible Gaming (
              <Link
                to="http://www.cprg.ca/"
                target="_blank"
                className="underline text-primary"
              >
                CPRG
              </Link>
              )
            </li>
          </ul>
          <br />
          In the UK, it is illegal to gamble under the age of 18. Key resources
          are:
          <br />
          <ul>
            <li>
              BeGambleAware (
              <Link
                to="https://www.begambleaware.org/"
                target="_blank"
                className="underline text-primary"
              >
                BeGambleAware
              </Link>
              )
            </li>
            <li>
              Gambling Therapy (
              <Link
                to="https://www.gamblingtherapy.org/"
                target="_blank"
                className="underline text-primary"
              >
                Gambling Therapy
              </Link>
              )
            </li>
            <li>
              Gamblers Anonymous UK (
              <Link
                to="https://www.gamblersanonymous.org/ga/"
                target="_blank"
                className="underline text-primary"
              >
                Gamblers Anonymous UK
              </Link>
              )
            </li>
            <li>
              GamCare (
              <Link
                to="https://www.gamcare.org.uk/"
                target="_blank"
                className="underline text-primary"
              >
                GamCare
              </Link>
              )
            </li>
            <li>
              GamStop (Self Exclusion) (
              <Link
                to="https://www.gamstop.co.uk/"
                target="_blank"
                className="underline text-primary"
              >
                GamStop
              </Link>
              )
            </li>
          </ul>
        </p>
      </div>
      <Footer />
    </div>
  );
}
