import Button from "components/Buttons";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import Arrow from "assets/lottie/Arrow.json";
const heroLine = require("assets/images/hero-line.svg");
const NBAIconSvg = require("assets/images/hero-nba.png");
const NFLIconSvg = require("assets/images/hero-nfl.png");
const MLBIconSvg = require("assets/images/hero-mlb.png");
const NCAABIconSvg = require("assets/images/hero-ncaab.png");

export default function Hero() {
  return (
    <section
      id="home"
      className="grid md:gap-8 pt-[10.875rem] justify-items-center content-center md:px-0 px-4 gap-4 md:mb-0 mb-8"
      style={{
        backgroundImage: `url(${require("assets/images/hero.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        // if screen height is less than 700px do height auto
      }}
    >
      <div className="relative w-full">
        {/* TODO: Add scroll icon */}
        <motion.img
          src={NFLIconSvg}
          alt="NBA"
          className="md:w-[4rem] lg:w-[auto] md:left-[5rem] w-12 lg:top-[2rem] -top-[5rem] lg:left-[5rem] xl:left-[8rem] 2xl:left-[10rem] xl:top-[0rem] absolute xl:block"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.25, delay: 0.25 }}
          viewport={{ once: true }}
        />
        <motion.img
          src={NBAIconSvg}
          alt="NFL"
          className="md:w-[auto] md:left-[5rem] w-8 lg:bottom-[14rem] bottom-[10rem] lg:left-[15rem] xl:left-[15rem] 2xl:left-[20rem] absolute xl:block"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.25, delay: 0.25 }}
          viewport={{ once: true }}
        />
        <motion.img
          src={MLBIconSvg}
          alt="MLB"
          className="md:w-[auto] md:right-[5rem] w-16 lg:top-[4rem] -top-[4rem] right-0 lg:right-[5rem] xl:right-[8rem] 2xl:right-[10rem] xl:top-[2rem] absolute xl:block"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.25, delay: 0.25 }}
          viewport={{ once: true }}
        />
        <motion.img
          src={NCAABIconSvg}
          alt="NCAAB"
          className="md:w-[auto] md:right-[5rem] w-16 lg:bottom-[14rem] bottom-[10rem] right-0 lg:right-[15rem] xl:right-[15rem] 2xl:right-[20rem] absolute xl:block"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.25, delay: 0.25 }}
          viewport={{ once: true }}
        />
        <div className="grid md:gap-8 justify-items-center content-center md:px-0 md:px-4 gap-4 md:mb-0 mb-8 relative w-full md:pb-12 pb-0 lg:pb-0">
          <motion.h1
            className="text-[#000] font-bold font-[GeneralSans] md:text-[4rem] text-[2rem] font-[500] uppercase text-center max-w-[990px] leading-[130%] font-monument"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.25 }}
            viewport={{ once: true }}
          >
            Driven by <span className="text-[#555]">Data Strategy</span>
            <br /> and <span className="text-[#555]">Innovation</span>
          </motion.h1>
          <motion.h2
            className="text-center font-sans md:text-[1.125rem] text-4 leading-[160%] max-w-[500px]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.25, delay: 0.125 }}
            viewport={{ once: true }}
          >
            Embrace a world where sports betting transcends guesswork and
            emotion. Venture into a realm of intelligent wagering, where
            probabilities guide precision.
          </motion.h2>
          <motion.div
            className="grid md:gap-8 gap-4 justify-items-center mt-[1.125rem] md:mt-0"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.25, delay: 0.25 }}
            viewport={{ once: true }}
          >
            <Link to="/register">
              <Button className="shadow-primaryButtonShadow w-fit text-[1.125rem] py-[1.5rem] px-[2.25rem] font-[400] tracking-[-0.72px]">
                Get Started Now
              </Button>
            </Link>
          </motion.div>
          <div
            style={{
              minHeight: "100px",
              marginBottom: "4rem",
            }}
          >
            {/* <motion.div
              style={{ contain: "paint" }}
              initial={{ opacity: 0, height: "0px" }}
              whileInView={{ opacity: 1, height: "100px" }}
              transition={{ duration: 0.5, delay: 0.5 }}
              viewport={{ once: true }}
            >
              <heroLine.ReactComponent />
            </motion.div> */}
            <Lottie
              animationData={Arrow}
              loop={true}
              style={{
                height: "80px",
              }}
              initialSegment={[0, 80]}
            />
          </div>
          {/* <motion.span
            className="text-[1.125rem] mb-16"
            // animate consistently from font-[400] to font-[500]
            initial={{ opacity: 0, fontWeight: 400 }}
            whileInView={{ opacity: 1, fontWeight: 500 }}
            transition={{ duration: 0.5, delay: 1 }}
            viewport={{ once: true }}
          >
            Scroll
          </motion.span> */}
        </div>
      </div>
    </section>
  );
}
