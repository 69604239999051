import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./css/general-sans.css";
import "./css/monument.css";
import Homepage from "modules/landing/home";
import Performance from "modules/landing/performance";
import { AuthProvider } from "helpers/contexts/AuthContext";
import { Toaster } from "react-hot-toast";
import Login from "modules/auth/login";
import Register from "modules/auth/register";
import ForgotPassword from "modules/auth/forgot-password";
import ResetPassword from "modules/auth/reset-password";
import { ProtectedRoute } from "routes/ProtectedRoutes";
import UserDashboard from "modules/user/dashboard";
import Plan from "modules/user/plan/current";
import FAQ from "modules/landing/home/FAQ";
// import Dashboard from "modules/admin/dashboard";
// import UserManagement from "modules/admin/users";
// import Selection from "modules/admin/selection";
import { QueryClient, QueryClientProvider } from "react-query";
import Payment from "modules/payment";
import TOS from "modules/landing/TOS/TOS.js";
import Privacy from "modules/landing/TOS/Privacy";
import ResponsibleGaming from "modules/landing/responsible-gaming/ResponsibleGaming";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Toaster
          toastOptions={{
            style: {
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            },
          }}
        />

        <Router />
      </QueryClientProvider>
    </div>
  );
}

function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "/performance",
      element: <Performance />,
    },
    {
      path: "/login",
      element: (
        <AuthProvider>
          <Login />
        </AuthProvider>
      ),
    },
    {
      path: "/register",
      element: (
        <AuthProvider>
          <Register />
        </AuthProvider>
      ),
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/dashboard",
      element: (
        <AuthProvider>
          <ProtectedRoute>
            <UserDashboard />
          </ProtectedRoute>
        </AuthProvider>
      ),
    },
    {
      path: "/plan",
      element: (
        <AuthProvider>
          <ProtectedRoute>
            <Plan />
          </ProtectedRoute>
        </AuthProvider>
      ),
    },
    {
      path: "/faq",
      element: <FAQ />,
    },
    {
      path: "/terms-of-service",
      element: <TOS />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/responsible-gaming",
      element: <ResponsibleGaming />,
    },
    {
      path: "*",
      element: <h1>404</h1>,
    },
  ]);

  // if (user && token) return <RouterProvider router={routerAuthenticate} />;
  return <RouterProvider router={router} />;
}

export default App;
