import Button from "components/Buttons";
import Input from "components/Input";
import Menu from "components/Menu";
import Footer from "../Footer";
import passwordValidation from "helpers/passwordValidation";
import { useState } from "react";
import { auth } from "helpers/api";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useResponsive from "helpers/useResponsive";
import Success from "modules/user/edit-profile/Success";
const eyeIcon = require("assets/images/eye.svg");
const eyeSlashIcon = require("assets/images/eye-slash.svg");

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmShown, setConfirmShown] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const rt = query.get("rt");
  const { isDesktop } = useResponsive();
  const schema = yup
    .object({
      password: yup
        .string()
        .test(
          "password",
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
          (value: any) => passwordValidation(value)
        ),
      confirm: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match")
        .required(),
    })
    .required();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);

    auth
      .resetPassword({
        resetPasswordToken: rt,
        password: data.password,
      })
      .then((res) => {
        // window.location.href = "/login";
        setSuccess(true);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };

  const handleSuccessClose = () => {
    setSuccess(false);
  };

  if (!rt) {
    window.location.href = "/forgot-password";
  }

  return (
    <div className="w-[100vw] md:max-lg:[auto] h-[100vh] lg:h-[100vh] grid content-center justify-items-center">
      <Menu type="landing" />
      <motion.div
        className="px-4 w-full flex justify-center md:max-lg:mt-64"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.125 }}
        viewport={{ once: true }}
        exit={{ opacity: 0 }}
      >
        {success ? (
          <div className="bg-[#FCFBFD] max-w-[28.125rem] w-full rounded-[24px] p-[2.5rem] h-fit">
            <Success
              onClose={handleSuccessClose}
              title="Password changed"
              description="Your password has been changed successfully!"
              button="Go to Login"
            />
          </div>
        ) : (
          <form
            className="bg-[#FCFBFD] max-w-[28.125rem] w-full rounded-[24px] p-[2.5rem] h-fit"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="!text-[1.5rem] title font-[500] mb-[1.5rem] uppercase">
              Reset password
            </h1>
            <p className="mb-6">Create a new password</p>
            <div className="grid gap-[1.5rem]">
              <div className="relative">
                <Input
                  id="password"
                  placeholder="Enter a new password"
                  label="Password"
                  register={register}
                  error={formState.errors.password?.message}
                  type={passwordShown ? "text" : "password"}
                  className="pr-12"
                />
                <button
                  className="absolute right-[1.125rem] top-[3rem]"
                  type="button"
                  onClick={() => setPasswordShown(!passwordShown)}
                  tabIndex={-1}
                >
                  <img
                    src={passwordShown ? eyeIcon.default : eyeSlashIcon.default}
                    alt="eye"
                  />
                </button>
              </div>
              <div className="relative">
                <Input
                  id="confirm"
                  placeholder="Re-enter password"
                  label="Confirm password"
                  register={register}
                  error={formState.errors.confirm?.message}
                  type={confirmShown ? "text" : "password"}
                  className="pr-12"
                />
                <button
                  className="absolute right-[1.125rem] top-[3rem]"
                  type="button"
                  onClick={() => setConfirmShown(!confirmShown)}
                  tabIndex={-1}
                >
                  <img
                    src={confirmShown ? eyeIcon.default : eyeSlashIcon.default}
                    alt="eye"
                  />
                </button>
              </div>
            </div>
            <Button
              disabled={!formState.isValid || isLoading}
              isLoading={isLoading}
              className="w-full py-[0.875rem] text-4 mt-6"
              type="submit"
            >
              Submit
            </Button>
          </form>
        )}
      </motion.div>
      {isDesktop && <Footer />}
    </div>
  );
}
