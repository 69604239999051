import { motion } from "framer-motion";
import useResponsive from "helpers/useResponsive";
import moment from "moment";
import { WAGER, WAGER_LABEL, sports } from "helpers/constant";
import { NoteTooltip } from "./NoteTooltip";
import { getESTDateTime } from "helpers/utils";
const infoIcon = require("assets/images/info.svg");

const getWager = (selection: any) => {
  if (selection?.type === "I") {
    const wager = selection?.teams?.[0]?.wager || selection?.teams?.[1]?.wager;
    if (wager === WAGER.Total) {
      return (
        selection?.teams?.[0]?.spreadPoints ||
        selection?.teams?.[1]?.spreadPoints
      );
    } else if (wager === WAGER.Spread) {
      return (
        selection?.teams?.[0]?.spreadPoints ||
        selection?.teams?.[1]?.spreadPoints + " points"
      );
    } else {
      return (
        WAGER_LABEL[wager] ||
        selection?.teams?.[0]?.wager ||
        selection?.teams?.[1]?.wager
      );
    }
  } else if (selection?.type === "TTT") {
    return "Two Team Teaser";
  } else if (selection?.type === "TTP") {
    const wager = selection?.teams?.[0]?.wager || selection?.teams?.[1]?.wager;
    return WAGER_LABEL[wager] || wager;
  }
};

const getPoints = (team: any) => {
  if (team?.wager === WAGER.Total) {
    return ` (${team?.spreadPoints})`;
  }
  if (team?.wager === WAGER.Spread) {
    return ` (${team?.spreadPoints} points)`;
  }
  return "";
};

export function PickCard({
  className,
  data,
  showResult,
}: {
  className?: string;
  data: any;
  showResult?: boolean;
}) {
  const { isMobile, isTablet } = useResponsive();
  const isSmallDevice = isMobile || isTablet;
  const isAbove70 = data.winningProbability >= 70;

  if (["TTT", "TTP"].includes(data?.type)) {
    return (
      <motion.div
        className={`${className} grid px-[1.25rem] ${
          isSmallDevice ? "gap-[0.875rem] p-3 lg:p-4" : ""
        } w-full bg-[white] rounded-[12px] border-[#C8C8C8] border-solid border-[1px]`}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
        viewport={{ once: true }}
        exit={{ opacity: 0 }}
      >
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <div className="flex-1 rounded-md w-full">
            {data?.teams?.map((team: any, i: number) => (
              <div
                key={i}
                className={`py-[1.25rem] flex lg:items-center gap-2.5 ${
                  i !== 0 ? "border-t border-t-[#DDD]" : ""
                }`}
              >
                <div>
                  <img
                    src={team?.logo}
                    alt="logo"
                    className={`${
                      isSmallDevice
                        ? "min-w-11 min-h-11 w-11 h-11 object-contain rounded-full"
                        : "w-[4rem] h-[4rem] object-contain rounded-full"
                    }`}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col lg:flex-row lg:items-center gap-1.5">
                    <span className="text-sm lg:text-lg font-medium text-black">
                      {team?.name}{" "}
                      <span className="whitespace-nowrap">
                        {getPoints(team)}
                      </span>
                    </span>
                    <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
                      <img
                        src={
                          sports.find(
                            (sport) =>
                              sport.name.toLowerCase() ===
                              team?.sports?.toLowerCase()
                          )?.icon
                        }
                        alt="NBA"
                        className="w-5 h-5 inline-block mr-[0.375rem]"
                      />
                      <span className="font-[500] text-[#252525] text-[15px]">
                        {team?.sports}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col lg:flex-row lg:items-center">
                      <span
                        className={`pr-[1.125rem] text-[15px] ${
                          isSmallDevice
                            ? "border-none text-[0.75rem]"
                            : "border-r-solid border-r-[1px] border-[#D0D0D0] text-sm lg:text-[1rem]"
                        } text-[#646464] font-[500]`}
                      >
                        Wager:
                        <span className="ml-9 lg:ml-1.5 text-sm lg:text-base text-[black]">
                          {getWager(data)}{" "}
                        </span>
                      </span>
                      <span
                        className={`${
                          isSmallDevice
                            ? "pl-0 text-[0.75rem]"
                            : "pl-[1.125rem] text-[13px] lg:text-[1rem]"
                        } text-[#646464] font-[500] text-[15px]`}
                      >
                        Date/time:
                        <span className="ml-2 lg:ml-1.5 text-[13px] lg:text-base text-[black]">
                          {getESTDateTime(team.date)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {!isSmallDevice && (
            <div className="h-full flex flex-col gap-3 py-[1.25rem]">
              <div
                className={`flex flex-col lg:flex-row gap-4 items-center flex-1`}
              >
                <div
                  className={`${
                    isAbove70 ? "bg-[#E9F8EB]" : " bg-[#F3F3F7]"
                  } grid ml-auto px-[1.25rem] py-[0.875rem] text-center rounded-[8px] gap-[0.375rem] h-full content-center`}
                >
                  <span className="text-[0.875rem] font-[500] text-[#3D3D3D]">
                    Winning probability
                  </span>
                  <span
                    className={`${
                      isAbove70 && "text-[#5CB030]"
                    } text-[1.5rem] font-[600] text-[#050505] font-[600]`}
                  >
                    {data.winningProbability}%
                  </span>
                </div>
              </div>
              {data?.note && <NoteTooltip note={data?.note} />}
            </div>
          )}
        </div>

        {isSmallDevice && (
          <div
            className={`${
              isAbove70 ? "bg-[#E9F8EB]" : " bg-[#F3F3F7]"
            } rounded-[8px] p-[0.75rem] text-center font-[500] text-[0.875rem] flex gap-[0.75rem] items-center justify-center`}
          >
            <span>Winning probability</span>
            <span
              className={`${
                isAbove70 && "text-[#5CB030]"
              } text-[1.5rem] font-[600] text-[#050505] font-[600]`}
            >
              {data.winningProbability}%
            </span>
            {data?.note && <NoteTooltip note={data?.note} />}
          </div>
        )}
      </motion.div>
    );
  }

  return (
    <motion.div
      className={`${className} grid p-3 ${
        isMobile ? "gap-[0.875rem] p-4" : ""
      } w-full bg-[white] rounded-[12px] border-[#C8C8C8] border-solid border-[1px]`}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.125 }}
      viewport={{ once: true }}
      exit={{ opacity: 0 }}
    >
      <div className={`flex gap-4 items-center p-2`}>
        <img
          src={data.teams[0].logo || data.teams[1].logo}
          alt={data.teams[0].name}
          className={`
          ${isMobile ? "w-[3.875rem] h-[3.875rem]" : "w-[5rem] h-[5rem]"}
        `}
        />

        <div className={`grid ${isMobile ? "gap-2" : "gap-[0.875rem]"} h-fit`}>
          <span
            className={`font-[500] ${
              isMobile ? "text-[1rem]" : "text-[1.125rem]"
            }`}
          >
            {data.teams[0].name || data.teams[1].name}{" "}
            {data?.winningStatus === 1 ? (
              <span className="text-primary text-[0.875rem]">(Win)</span>
            ) : data?.winningStatus === -1 ? (
              <span className="text-[#F44336] text-[0.875rem]">(Loss)</span>
            ) : data?.winningStatus === 2 ? (
              <span className="text-[#888] text-[0.875rem]">(Push)</span>
            ) : (
              <span className="text-[#888] text-[0.875rem]">(Pending)</span>
            )}
          </span>
          <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
            <img
              src={
                sports?.find(
                  (sport) =>
                    sport?.name?.toLowerCase() ===
                      data?.teams?.[0]?.sports?.toLowerCase() ||
                    sport?.name?.toLowerCase() ===
                      data?.teams?.[1]?.sports.toLowerCase()
                )?.icon
              }
              alt="NBA"
              className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
            />
            <span className="font-[500] text-[0.75rem]">
              {data?.teams[0].sports || data?.teams[1].sports}
            </span>
          </div>
        </div>

        {!isMobile && (
          <div
            className={`${
              isAbove70 ? "bg-[#E9F8EB]" : " bg-[#F3F3F7]"
            } grid ml-auto px-[1.25rem] py-[0.875rem] text-center rounded-[8px] gap-[0.375rem]`}
          >
            <span className="text-[0.875rem] font-[500] text-[#3D3D3D]">
              Winning probability
            </span>
            <span
              className={`${
                isAbove70 && "text-[#5CB030]"
              } text-[1.5rem] font-[600] text-[#050505] font-[600]`}
            >
              {data.winningProbability}%
            </span>
          </div>
        )}
      </div>

      {isSmallDevice && (
        <div
          className={`${
            isAbove70 ? "bg-[#E9F8EB]" : " bg-[#F3F3F7]"
          } rounded-[8px] p-[0.75rem] text-center font-[500] text-[0.875rem] flex gap-[0.75rem] items-center justify-center`}
        >
          <span className="text-[13px] font-medium">Winning probability</span>
          <span
            className={`${
              isAbove70 && "text-[#5CB030]"
            } text-lg lg:text-[1.5rem] font-[600] text-[#050505] font-[600]`}
          >
            {data.winningProbability}%
          </span>
          {data?.note && <NoteTooltip note={data?.note} />}
        </div>
      )}
      <div
        className={`lg:bg-[#F3F3F7] mt-2.5 rounded-md flex items-center justify-between ${
          isSmallDevice ? "border-none grid gap-2 py-4" : "px-[18px] py-4"
        }`}
      >
        <div className="flex flex-col lg:flex-row lg:items-center">
          <span
            className={`pr-[1.125rem] text-[15px] ${
              isSmallDevice
                ? "border-none text-[0.75rem]"
                : "border-r-solid border-r-[1px] border-[#D0D0D0] text-[1rem]"
            } text-[#646464] font-[500]`}
          >
            Wager:
            <span className="ml-9 lg:ml-[0.375rem] text-base text-[black]">
              {getWager(data)}
            </span>
          </span>
          <span
            className={`${
              isSmallDevice
                ? "pl-0 text-[0.75rem]"
                : "pl-[1.125rem] text-[1rem]"
            } text-[#646464] font-[500] text-[15px]`}
          >
            Date/time:
            <span className="ml-2 lg:ml-[0.375rem] text-base text-[black]">
              {getESTDateTime(data?.teams?.[0]?.date || data?.teams?.[1]?.date)}
            </span>
          </span>
        </div>
        {!isSmallDevice && data?.note && <NoteTooltip note={data?.note} />}
      </div>
    </motion.div>
  );
}
export function PickCardSkeleton({ className }: { className?: string }) {
  const { isMobile } = useResponsive();

  return (
    <motion.div
      className={`${className} grid ${
        isMobile ? "gap-[0.875rem] p-4" : ""
      } w-full bg-[white] rounded-[12px] border-[#C8C8C8] border-solid border-[1px] animate-pulse`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <div
        className={`flex gap-4 items-center ${isMobile ? "" : "p-[1.25rem]"}`}
      >
        <div
          className={`${
            isMobile ? "w-[3.875rem] h-[3.875rem]" : "w-[5rem] h-[5rem]"
          } bg-gray-300 rounded-full`}
        />
        <div
          className={`grid ${
            isMobile ? "gap-2" : "gap-[0.875rem]"
          } h-fit w-[200px]`}
        >
          <div className="h-4 bg-gray-300 rounded w-3/4"></div>
          <div className="h-6 bg-gray-300 rounded w-1/2"></div>
        </div>
        {!isMobile && (
          <div
            className={`${"bg-gray-200"} grid ml-auto px-[1.25rem] py-[0.875rem] text-center rounded-[8px] gap-[0.375rem]`}
          >
            <span className="text-[0.875rem] font-[500] text-[#3D3D3D]">
              Winning probability
            </span>

            <div className="h-6 bg-gray-300 rounded w-1/2 mx-auto mt-4"></div>
          </div>
        )}
      </div>
      {isMobile && (
        <div
          className={`${"bg-gray-200"} rounded-[8px] p-[0.75rem] text-center font-[500] text-[0.875rem] flex gap-[0.75rem] items-center justify-center`}
        >
          <span>Winning probability</span>
          <div className="h-6 bg-gray-300 rounded w-16"></div>
        </div>
      )}
      <div
        className={`${
          isMobile
            ? "border-none grid gap-2"
            : "border-t-[1px] border-t-solid border-t-[#C8C8C8] mx-8 py-[1.25rem] flex"
        }`}
      >
        <span
          className={`pr-[1.125rem] ${
            isMobile
              ? "border-none text-[0.75rem]"
              : "border-r-solid border-r-[1px] border-[#D0D0D0] text-[1rem]  gap-4"
          } text-[#646464] font-[500] flex items-center`}
        >
          Wager:
          <div
            className={`${
              isMobile && "ml-4"
            } h-6 bg-gray-300 rounded w-24 mx-auto`}
          ></div>
        </span>
        <span
          className={`pr-[1.125rem] ${
            isMobile
              ? "border-none text-[0.75rem]"
              : "text-[1rem] gap-4 pl-[1.125rem]"
          } text-[#646464] font-[500] flex items-center `}
        >
          Date/Time:
          <div
            className={`${
              isMobile && "ml-4"
            } h-6 bg-gray-300 rounded w-24 mx-auto`}
          ></div>
        </span>
      </div>
    </motion.div>
  );
}

// export function SubscriptionCard() {
//   return (
//     <motion.div
//       className="w-4/12 bg-[#FAF9F5] p-[1.5rem] rounded-[12px] relative"
//       initial={{ opacity: 0 }}
//       whileInView={{ opacity: 1 }}
//       transition={{ duration: 0.5 }}
//       viewport={{ once: true }}
//       exit={{ opacity: 0 }}
//     >
//       <div className="pb-[1.25rem] border-b-[1px] border-b-solid border-[#CCDFCE] justify-between flex">
//         <span className="text-[1.125rem] uppercase tracking-[-0.36px] font-[500]">
//           Your current plan
//         </span>
//         <button className="text-[#66BA3C] text-[1rem] font-[500]">
//           Change
//         </button>
//       </div>
//       <div className="pt-[1.25rem] grid">
//         <span className="mb-[0.75rem] text-[#4783C9] font-[600] uppercase">
//           Conservative
//         </span>
//         <span className="mb-2 text-[1.75rem] font-[600]">$99/month</span>
//         <span className="text-[1.25rem]">(Billed half yearly)</span>
//         <span className="absolute bottom-[1.5rem] text-[#717171] text-[1rem] font-[500]">
//           Next billing on Jul 01, 2024
//         </span>
//       </div>
//     </motion.div>
//   );
// }
