import Button from "components/Buttons";
import useResponsive from "helpers/useResponsive";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import EditProfile from "modules/user/edit-profile";
import { useQuery } from "react-query";
import { user } from "helpers/api";
import capitalize from "helpers/hooks/capitalize";
import LogoutPopup from "modules/user/edit-profile/LogoutPopup";
const logo = require("assets/images/logo.svg");
const mobileMenu = require("assets/images/menu.svg");
const closeButton = require("assets/images/x.svg");
const userIcon = require("assets/images/user.svg");
const chevronBlack = require("assets/images/chevron-black.svg");
const emailIcon = require("assets/images/email.svg");
const phoneIcon = require("assets/images/phone.svg");

const HeaderLoadingSkeleton = () => {
  return (
    <header
      className={`px-4 py-3 border-b-[1px] border-b-solid border-b-[#E4DFD0] bg-[white] flex w-full backdrop-blur-[10px] opacity-[1] z-[99999]`}
    >
      <div className="w-full flex items-center justify-between px-[2.75rem] py-[0.5rem]">
        <Link to="/">
          <logo.ReactComponent className="max-h-[3rem] w-auto" />
        </Link>
        <div className="animate-pulse">
          <div className="w-[250px] h-[24px] bg-gray-300 rounded-md"></div>
        </div>
      </div>
    </header>
  );
};

export default function Menu({
  type = "landing",
  fixed,
  transparentTop,
}: {
  type?: "landing" | "login" | "register" | "user-dashboard";
  fixed?: boolean;
  transparentTop?: boolean;
}) {
  const { isTablet } = useResponsive();
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { data, isLoading } = useQuery("user", user.get, {
    enabled: window.localStorage.getItem("token") ? true : false,
  });

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  const linkClass =
    "md:mr-4 font-[500] md:text-[0.875rem] font-sans text-[black] hover:text-[#101010] hover:opacity-[70%] transition-all";
  const activeLinkClass =
    "md:mr-4 font-[500] md:text-[0.875rem] font-sans text-[black] hover:text-[#101010] transition-all font-[600]";
  const activeLink = window.location.pathname.split("/")[1];

  useEffect(() => {
    // limit App scroll when menu is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // landing, not mobile, no token
  if (
    type === "landing" &&
    !isTablet &&
    !window.localStorage.getItem("token")
  ) {
    return (
      <Header type={type} fixed={fixed} transparentTop={transparentTop}>
        <div className="ml-auto flex gap-2 items-center">
          <Link
            to="/performance"
            className={
              activeLink === "performance" ? activeLinkClass : linkClass
            }
          >
            Check Performance
          </Link>
          <Link to="/register">
            <Button>Get started</Button>
          </Link>
          <Link to="/login">
            <Button variant="secondary">Login</Button>
          </Link>
        </div>
      </Header>
    );
  }
  // landing, mobile, no token
  else if (
    type === "landing" &&
    isTablet &&
    !window.localStorage.getItem("token")
  ) {
    return (
      <Header type={type} fixed={fixed} transparentTop={transparentTop}>
        <div
          className={`fixed top-0 left-0 ${
            isOpen ? "w-full" : "w-0 overflow-hidden"
          } h-[100vh] bg-[white] z-[100] transition-all`}
        >
          <button onClick={handleOpen} className="absolute top-0 right-0 p-4">
            <closeButton.ReactComponent className="max-h-[1.5rem] w-auto" />
          </button>
          <div className="text-center h-full grid items-center content-center mx-[3.125rem]">
            <Link
              to="/performance"
              className={
                (activeLink === "performance" ? activeLinkClass : linkClass) +
                " mb-[0.75rem] mr-[0] border-solid border-[#C5D9C9] border-[1px] rounded-[32px] p-[0.875rem] text-[0.875rem]"
              }
            >
              Check Performance
            </Link>
            <Link to="/register" className="mb-[0.75rem] w-full">
              <Button className="w-full leading-[180%] !py-[0.875rem]">
                Get started
              </Button>
            </Link>
            <Link to="/login" className="w-full">
              <Button
                variant="secondary"
                className="w-full leading-[180%] !py-[0.875rem] border-[0px]"
              >
                Login
              </Button>
            </Link>
          </div>
        </div>
        <div className="ml-auto flex gap-2 items-center">
          <button onClick={handleOpen}>
            <mobileMenu.ReactComponent className="max-h-[1.5rem] w-auto" />
          </button>
        </div>
      </Header>
    );
  }
  // login
  else if (type === "login") {
    return (
      <Header type={type} fixed={fixed} transparentTop={transparentTop}>
        <div className="ml-auto flex gap-2 items-center">
          <Link to="/register">
            <Button>Sign up</Button>
          </Link>
        </div>
      </Header>
    );
  }
  // register
  else if (type === "register") {
    return (
      <Header type={type} fixed={fixed} transparentTop={transparentTop}>
        <div className="ml-auto flex gap-2 items-center">
          <Link to="/login">
            <Button>Login</Button>
          </Link>
        </div>
      </Header>
    );
  }
  // user dashboard, not mobile
  else if ((type === "user-dashboard" || type === "landing") && !isTablet) {
    // if (isLoading) return <HeaderLoadingSkeleton />;
    return (
      <Header type={type} fixed={fixed} transparentTop={transparentTop} whiteBg>
        <div className="ml-auto flex gap-2 items-center">
          <Link
            to="/dashboard"
            className={activeLink === "dashboard" ? activeLinkClass : linkClass}
          >
            Selections
          </Link>
          <Link
            to="/plan"
            className={activeLink === "plan" ? activeLinkClass : linkClass}
          >
            Your Plan
          </Link>
          <Link
            to="/performance"
            className={
              activeLink === "performance" ? activeLinkClass : linkClass
            }
          >
            Check Performance
          </Link>
          <Link
            to="/faq"
            className={`
              ${activeLink === "faq" ? activeLinkClass : linkClass}
              `}
          >
            FAQs
          </Link>
          {isLoading && (
            <div className="relative max-w-[8.25rem]">
              <button className="border-[#DDDDDD] border-[1px] border-solid pr-3 p-1 bg-[white] flex rounded-[60px] items-center relative hover:bg-[#e5ede586] transition-all animate-pulse">
                <div className="!rounded-full">
                  <div className="!rounded-full h-8 w-8 min-h-8 min-w-8 bg-gray-300">
                    {/* Skeleton for the image */}
                  </div>
                </div>
                <div className="pl-[0.375rem] pr-[0.25rem] font-[500] text-[1rem] w-8 bg-gray-300 rounded">
                  {/* Skeleton for the text */}
                </div>
                <div className="w-5 h-5 ml-2 bg-gray-300 rounded-full">
                  {/* Skeleton for the chevron icon */}
                </div>
              </button>
              {/* Optional: Skeleton for the dropdown itself if it's visible by default */}
            </div>
          )}
          {!isLoading && (
            <div className="relative">
              <button
                className="border-[#DDDDDD] border-[1px] border-solid pr-3 p-1 bg-[white] flex rounded-[60px] items-center relative hover:bg-[#e5ede586] transition-all"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className={`rounded-full `}>
                  <div
                    className={`${
                      data?.profileImg
                        ? "h-8 w-8 min-h-8 min-w-8"
                        : "h-8 w-8 min-h-[1.5rem] min-w-[1.5rem]"
                    }`}
                  >
                    <img
                      src={data?.profileImg || userIcon.default}
                      alt="profile"
                      className={`w-full h-full rounded-full object-contain`}
                    />
                  </div>
                </div>
                <span className="pl-[0.375rem] pr-[0.25rem] font-[500] text-[1rem]">
                  {capitalize(data?.firstName)}
                </span>
                <motion.div
                  className="w-auto"
                  animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <chevronBlack.ReactComponent className="w-auto" />
                </motion.div>
              </button>
              <Dropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} />
            </div>
          )}
        </div>
      </Header>
    );
  }

  // user dashboard, mobile
  return (
    <Header type={type} fixed={fixed} transparentTop={transparentTop}>
      <div
        className={`fixed top-0 left-0 ${
          isOpen ? "w-full" : "w-0 overflow-hidden"
        } h-[100vh] bg-[#FCFBFD] z-[100] transition-all`}
      >
        <button onClick={handleOpen} className="absolute top-0 right-0 p-4">
          <closeButton.ReactComponent className="max-h-[1.5rem] w-auto" />
        </button>
        <div className="text-center h-full grid items-center content-center gap-8">
          <Link
            to="/dashboard"
            className={activeLink === "dashboard" ? activeLinkClass : linkClass}
          >
            Selections
          </Link>
          <Link
            to="/plan"
            className={activeLink === "plan" ? activeLinkClass : linkClass}
          >
            Your Plan
          </Link>
          <Link
            to="/performance"
            className={
              activeLink === "performance" ? activeLinkClass : linkClass
            }
            // style={{
            //   fontSize: "0.875rem",
            // }}
          >
            Check Performance
          </Link>
          <Link
            to="/faq"
            className={activeLink === "faq" ? activeLinkClass : linkClass}
          >
            FAQs
          </Link>
        </div>
      </div>
      <div className="ml-auto flex gap-4 items-center">
        <div className="relative">
          <button
            className="bg-[white] flex rounded-[60px] items-center relative"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className={`rounded-full`}>
              <div
                className={"h-[2rem] w-[2rem] min-h-[1.5rem] min-w-[1.5rem]"}
              >
                <img
                  src={data?.profileImg || userIcon.default}
                  alt="profile"
                  className={`w-full h-full rounded-full object-contain`}
                />
              </div>
            </div>
          </button>
          <Dropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} />
        </div>
        <button onClick={handleOpen}>
          <mobileMenu.ReactComponent className="max-h-[1.5rem] w-auto" />
        </button>
      </div>
    </Header>
  );
}

function HeaderLogo() {
  return (
    <Link to="/">
      <logo.ReactComponent className="max-h-[3rem] w-auto" />
    </Link>
  );
}

function Header({
  children,
  type,
  fixed,
  transparentTop,
  whiteBg,
}: {
  children: React.ReactNode;
  type: string;
  fixed?: boolean;
  transparentTop?: boolean;
  whiteBg?: boolean;
}) {
  const { isTablet } = useResponsive();
  // turn header from transparent to white when scrolled
  useEffect(() => {
    if (window.location.pathname === "/") {
      // have transparent header on landing page
      const header = document.querySelector("header");
      if (header && transparentTop) {
        header.style.backgroundColor = "transparent";
        header.style.borderBottomColor = "#B3CBB6";
        header.classList.remove("shadow-menu");
      }
    }
    if (
      window.location.pathname === "/performance" ||
      window.location.pathname === "/faq"
    ) {
      const handleScroll = () => {
        const header = document.querySelector("header");
        if (header) {
          if (window.scrollY > 0) {
            header.style.backgroundColor = "white";
            header.style.borderBottomColor = "white";
            header.classList.add("shadow-menu");
          } else {
            header.style.backgroundColor = "white";
            header.style.borderBottomColor = "#B3CBB6";
            header.classList.remove("shadow-menu");
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
    // if /performance keep white background
    if (type === "landing" && window.location.pathname !== "/performance") {
      const handleScroll = () => {
        const header = document.querySelector("header");
        if (header && transparentTop) {
          if (window.scrollY > 0) {
            header.style.backgroundColor = "white";
            header.style.borderBottomColor = "white";
            header.classList.add("shadow-menu");
          } else {
            header.style.backgroundColor = "transparent";
            header.style.borderBottomColor = "#B3CBB6";
            header.classList.remove("shadow-menu");
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [type, transparentTop, window.location.pathname]);

  return (
    <header
      className={`${isTablet ? "px-4 py-3" : "px-[2.5rem] py-[1.25rem]"}  ${
        (window.location.pathname === "/" ||
          window.location.pathname === "/plan") &&
        "border-b-[1px] border-b-solid border-b-[#E4DFD0]"
      } bg-[#F4F3F8] flex ${
        type === "user-dashboard" && !fixed ? "relative" : "fixed"
      } w-full opacity-[1] z-[99] transition-all transition-duration-800`}
      style={{
        borderBottomColor: transparentTop ? "#B3CBB6" : "#E4DFD0",
        // backgroundColor: transparentTop ? "transparent" : "#F4F3F8",
        backgroundColor: whiteBg
          ? "white"
          : transparentTop && window.location.pathname !== "/performance"
          ? "transparent"
          : "white",
      }}
    >
      <HeaderLogo />
      {children}
    </header>
  );
}
function Dropdown({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { data, isLoading } = useQuery("user", user.get);
  const [logoutPopup, setLogoutPopup] = useState(false);

  if (isLoading) return null;

  return (
    <>
      <EditProfile
        isOpen={isEditModalOpen}
        handleModal={() => setIsEditModalOpen(!isEditModalOpen)}
      />

      <motion.div
        className="shadow-menuDropdown absolute top-[3.25rem] right-0  bg-[white] rounded-[10px] p-2 z-[101] shadow-lg min-w-[260px] h-auto p-[1.5rem] overflow-hidden"
        initial={{ maxHeight: "0px", padding: "0px" }}
        animate={{
          maxHeight: isOpen ? "500px" : "0px",
          padding: isOpen ? "24px" : "0px",
        }}
        exit={{ maxHeight: "0px", padding: "0px" }}
      >
        <motion.div
          className="grid justify-center text-center"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -10 }}
          exit={{ opacity: 0, display: "none" }}
        >
          <div className="grid">
            <div
              className={`rounded-full w-fit mb-[1.25rem] justify-self-center`}
            >
              <img
                src={data?.profileImg || userIcon.default}
                alt="profile"
                className="h-[3.5rem] w-[3.5rem] rounded-full object-contain"
              />
            </div>
            <div className="grid gap-[0.5rem] justify-items-center mb-[1.25rem]">
              <span className="text-[1.25rem] font-[700]">
                {capitalize(data?.firstName) + " " + capitalize(data?.lastName)}
              </span>
              <span className="text-[1rem] font-[500] flex gap-2 items-center">
                <emailIcon.ReactComponent className="max-h-[1.5rem] w-auto" />
                {data.email}
              </span>
              <span className="text-[1rem] font-[500] flex gap-2 items-center">
                <phoneIcon.ReactComponent className="max-h-[1.5rem] w-auto" />
                {data.mobile}
              </span>
            </div>
            <CustomButton>
              <motion.button
                className="mx-[1.5rem] my-[0.625rem]"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setIsOpen(false);
                  setIsEditModalOpen(true);
                }}
              >
                <span className="font-[500] text-[0.875rem]">Edit profile</span>
              </motion.button>
            </CustomButton>
            <motion.button
              className="mt-[0.75rem] font-[500] text-[0.875rem] text-[#B70101]"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                setIsOpen(false);
                setLogoutPopup(true);
              }}
            >
              Logout
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
      {/* all over behind dropdown click closes the dropdown */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full z-[100] h-[100vh]"
          onClick={() => setIsOpen(false)}
        />
      )}
      <LogoutPopup
        isOpen={logoutPopup}
        handleModal={() => setLogoutPopup(!logoutPopup)}
      />
    </>
  );
}

const CustomButton = styled.button`
  border: solid 1px transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #62df47, #aef3a0);
  background-origin: border-box;
  background-clip: content-box, border-box;
  /* on hover animate to background image */
  transition: all 0.3s ease;
  &:hover {
    background-image: linear-gradient(white, white),
      linear-gradient(to bottom left, #62df47, #aef3a0);
  }
`;
