import { motion } from "framer-motion";
import useResponsive from "helpers/useResponsive";
const closeIcon = require("assets/images/x.svg");
const backIcon = require("assets/images/arrow-left.svg");

export default function Modal({
  children,
  isOpen,
  handleModal,
  title,
  secondaryTitle,
  customTitle,
  zIndex,
  className,
  width,
  noTitle,
  hasBack,
  handleBack,
  noAnimation,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  handleModal?: () => void;
  title?: string;
  secondaryTitle?: React.ReactNode;
  customTitle?: React.ReactNode;
  zIndex?: string;
  className?: string;
  width?: string;
  noTitle?: boolean;
  hasBack?: boolean;
  handleBack?: () => void;
  noAnimation?: boolean;
}) {
  // stop scroll when modal is open
  const { isMobile } = useResponsive();
  // if (isOpen) {
  //   document.body.style.overflow = "hidden";
  // } else {
  //   document.body.style.overflow = "auto";
  // }

  return (
    <motion.div
      animate={{
        opacity: isOpen ? 1 : 0,
        display: isOpen ? "block" : "none",
      }}
      exit={{ display: "none" }}
      transition={
        noAnimation
          ? {
              duration: 0,
            }
          : { duration: 0.125 }
      }
      className={className}
    >
      <motion.div
        className={`w-full h-[100%] fixed top-0 left-0 z-[9999] overflow-auto`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.125 }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            // width: "440px",
            width: isMobile ? "95%" : width ? width : "440px",
            borderRadius: "16px",
            background: "white",
            padding: "1.75rem",
            zIndex: zIndex || "101",
            // backdropFilter: "blur(1000px)",
          }}
          className="shadow-xl"
        >
          {!noTitle && (
            <div className="flex justify-between mb-[1.875rem] header">
              {title && (
                <h2 className="text-[1.125rem] font-[700] items-center flex gap-3">
                  {hasBack && (
                    <button onClick={handleBack}>
                      <img src={backIcon.default} alt="back" />
                    </button>
                  )}{" "}
                  {title}
                </h2>
              )}
              <div className="flex gap-[0.625rem] ml-auto">
                {secondaryTitle && secondaryTitle}
                {handleModal && (
                  <button onClick={handleModal}>
                    <closeIcon.ReactComponent />
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="max-h-[calc(100vh-250px)] overflow-auto">
            {children}
          </div>
        </div>
        {/* all over behind dropdown click closes the dropdown */}
        {isOpen && (
          <div
            className="fixed top-0 left-0 w-full z-[100] h-[100vh] opacity-[0.3] bg-[black]"
            onClick={handleModal}
          />
        )}
      </motion.div>
    </motion.div>
  );
}
