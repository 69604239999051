import { motion } from "framer-motion";
import { useState } from "react";
import PlanCard from "components/PlanCard";
import useResponsive from "helpers/useResponsive";
import { useQuery } from "react-query";
import { subscription } from "helpers/api";
import Spinner from "components/Spinner";
import Payment from "modules/payment";
const Ellipse = require("assets/images/subscription-ellipse.svg");

export default function Subscription() {
  const { isMobile } = useResponsive();
  const [option, setOption] = useState(
    "monthly" as "monthly" | "half_yearly" | "yearly"
  );
  const [selectedPlan, setSelectedPlan] = useState({
    id: "",
    duration: "",
  });
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const { data, isLoading } = useQuery("subscriptions", subscription.get);

  if (isLoading) return <Spinner />;

  return (
    <>
      {isPaymentOpen && (
        <Payment
          state={{
            plan: selectedPlan.id,
            duration: selectedPlan.duration,
          }}
          isOpen={isPaymentOpen}
          handleModal={() => {
            setIsPaymentOpen(!isPaymentOpen);
          }}
        />
      )}

      <section
        id="subscription"
        className="grid gap-[5rem] justify-items-center md:mt-[11.25rem] mt-[5rem] px-1.5 md:px-4"
      >
        <div className="lg:px-[2rem] relative pt-[60px] md:pt-[8.125rem] py-8 bg-[#fff] grid justify-items-center w-full">
          <Ellipse.ReactComponent className="absolute rounded-[1.25rem] md:rounded-[2.5rem] max-w-[100vw] h-[300px] md:h-auto md:w-[60%] lg:w-[60%] right-0" />
          <motion.h3
            // className="font-sans uppercase md:text-[4rem] text-[2rem] font-[500]"
            className="title uppercase md:text-[3rem] text-[2rem] font-[500] text-center"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            Subscription Plans
          </motion.h3>
          <motion.p
            className="text-center font-sans md:text-[1.125rem] text-4 max-w-[750px] leading-[160%] mt-[1.25rem] md:mb-[3.75rem] mb-[2.75rem] px-4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            Choose the plan that aligns with your selection frequency and
            probability preferences, or opt for our Pro Plan to gain access to
            all selections.
          </motion.p>
          <motion.div
            className="w-full md:w-fit p-[0.25rem] rounded-[70px] md:mb-[2.5rem] mb-[1.5rem] bg-[#DCEADF] flex gap-1 z-[50]"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <button
              className={`md:w-fit w-1/3 px-4 md:px-[1.5rem] md:px-[1.75rem] py-[0.875rem] rounded-[50px]
             ${
               option === "monthly"
                 ? "bg-[white] text-[black]"
                 : "text-[#6A6A6A] hover:bg-[#ffffff80]"
             } font-[500] md:text-[1.125rem] hover:text-[black] transition-all`}
              onClick={() => setOption("monthly")}
            >
              Monthly {!isMobile && "Plan"}
            </button>
            <button
              className={`md:w-fit w-1/3 ${
                option === "half_yearly"
                  ? "bg-[white] text-[black]"
                  : "text-[#6A6A6A] hover:bg-[#ffffff80]"
              } px-4 md:px-[1.5rem] md:px-[1.75rem] py-[0.875rem] rounded-[50px] md:text-[1.125rem] font-[500] hover:text-[black] transition-all`}
              onClick={() => setOption("half_yearly")}
            >
              Half Yearly {!isMobile && "Plan"}
            </button>
            <button
              className={`md:w-fit w-1/3 
            ${
              option === "yearly"
                ? "bg-[white] text-[black]"
                : "text-[#6A6A6A] hover:bg-[#ffffff80]"
            } 
            px-4 md:px-[1.5rem] md:px-[1.75rem] py-[0.875rem] rounded-[50px] md:text-[1.125rem] font-[500] hover:text-[black] transition-all`}
              onClick={() => setOption("yearly")}
            >
              Yearly {!isMobile && "Plan"}
            </button>
          </motion.div>
          <div className="grid gap-4 md:w-[auto] w-full">
            <div className="flex flex-col lg:flex-row gap-[1rem] w-full flex-wrap md:px-4 lg:flex-nowrap max-w-[1100px] justify-center">
              {data?.slice(0, 3).map?.((plan: any) => (
                <div
                  className="flex-1 lg:flex-[none] lg:w-[calc(33%-0.5rem)] xl:min-w-[350px]"
                  key={plan.id}
                >
                  <PlanCard
                    id={plan.id}
                    key={plan.id}
                    title={plan.name}
                    price={{
                      monthly: plan.priceMonthly,
                      half: plan.priceHalfYearly,
                      yearly: plan.priceYearly,
                    }}
                    features={plan.benefits?.split?.("$$")}
                    primary={plan.textColor}
                    secondary={plan.bgColor}
                    delay={0}
                    option={option}
                    openPayment={() => setIsPaymentOpen(true)}
                    setSelectedPlan={setSelectedPlan}
                  />
                </div>
              ))}
            </div>
            <div className="flex flex-col lg:flex-row gap-[1rem] w-full flex-wrap md:px-4 lg:flex-nowrap max-w-[1100px] justify-center">
              {data?.slice(3, 6).map?.((plan: any) => (
                <div
                  className="flex-1 lg:flex-[none] lg:w-[calc(33%-0.5rem)] xl:min-w-[350px]"
                  key={plan.id}
                >
                  <PlanCard
                    id={plan.id}
                    key={plan.id}
                    title={plan.name}
                    price={{
                      monthly: plan.priceMonthly,
                      half: plan.priceHalfYearly,
                      yearly: plan.priceYearly,
                    }}
                    features={plan.benefits?.split?.("$$")}
                    primary={plan.textColor}
                    secondary={plan.bgColor}
                    delay={0}
                    option={option}
                    openPayment={() => setIsPaymentOpen(true)}
                    setSelectedPlan={setSelectedPlan}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                  <div className="w-4 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
                <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="mrr">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}
