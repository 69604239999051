/* eslint-disable import/no-anonymous-default-export */
// import axios from "axios";
import client from "./client";

export default {
  put: ({
    firstName,
    lastName,
    mobile,
    profileImg,
  }: {
    firstName: string;
    lastName: string;
    mobile: string;
    profileImg?: string;
  }) =>
    client
      .put(`/users/${localStorage.getItem("id")}`, {
        firstName,
        lastName,
        mobile,
        profileImg,
      })
      .then((res) => res.data),
  get: () =>
    client.get(`/users/${localStorage.getItem("id")}`).then((res) => res.data),
  getImageSignedURL: (type?: string) => {
    return client
      .get(
        `/users/${localStorage.getItem(
          "id"
        )}/images/signed-url?uploadType=USER_PROFILE&mimeType=${type}`
      )
      .then((res) => res.data);
  },
  changePassword: (oldPassword: string, newPassword: string) =>
    client
      .patch(`/users/${localStorage.getItem("id")}/password`, {
        oldPassword,
        newPassword,
      })
      .then((res) => res.data),
  inquiry: (data: any) => client.post(`/inquiry`, data).then((res) => res.data),
};
