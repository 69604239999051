import { motion } from "framer-motion";
import useResponsive from "helpers/useResponsive";
import { PickCard } from "./Cards";
import { useQuery } from "react-query";
import moment from "moment";
import selection from "helpers/api/selection";
import { useEffect, useState } from "react";
const SelectionDayBg = require("assets/images/selection-day.svg");
const chevronIcon = require("assets/images/chevron-black.svg");

export default function Selection() {
  const { isMobile, isMobileLandScape } = useResponsive();
  const { data, isLoading } = useQuery("todays-selections", () =>
    selection.get(moment().format("YYYY-MM-DD"), 0, 100)
  );
  const [filtered, setFiltered] = useState([]);
  const [isTabletDropdownOpen, setisTabletDropdownOpen] = useState(false);

  useEffect(() => {
    if (!isLoading && data) {
      setFiltered(data?.filter((item: any) => item.winningProbability >= 70));
    }
  }, [data, isLoading]);

  if (isLoading || filtered?.length === 0) return null;

  if (isMobile) {
    return (
      <motion.div
        className={`pb-[1.5rem] bg-[white] rounded-[12px] w-full overflow-hidden`}
        style={{
          minHeight: !isMobileLandScape ? "4.6875rem" : "6.625rem",
        }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        animate={
          isTabletDropdownOpen
            ? "open"
            : isMobileLandScape
            ? "closedMobile"
            : "closed"
        }
        variants={{
          open: { maxHeight: "100%" },
          closedMobile: { maxHeight: "6.625rem" },
          closed: { maxHeight: "4.6875rem" },
        }}
        exit={{ opacity: 0 }}
      >
        <button
          className={`p-[1.5rem] text-[1.125rem] font-[500] uppercase flex justify-between w-full text-start items-center`}
          onClick={() => setisTabletDropdownOpen(!isTabletDropdownOpen)}
        >
          <div className="md:flex grid gap-1">
            Selection of the day{" "}
            <span className="text-[#8A8A8A]">
              ({moment().format("MMM DD, YYYY | ddd")})
            </span>
          </div>
          <motion.div
            className={isTabletDropdownOpen ? "transform rotate-180" : ""}
            initial={false}
            animate={isTabletDropdownOpen ? "open" : "closed"}
            variants={{
              open: { rotate: 180 },
              closed: { rotate: 0 },
            }}
          >
            <chevronIcon.ReactComponent />
          </motion.div>
        </button>
        <div className="flex flex-wrap pb-[0.75rem] px-[0.75rem] gap-[1.25rem]">
          {filtered?.map((item: any, index: number) => {
            return (
              <div className="flex gap-[1.25rem] w-full">
                <PickCard key={index} data={item} />
              </div>
            );
          })}
        </div>
      </motion.div>
    );
  }

  return (
    <div
      className="flex gap-[1.25rem] mb-[1.25rem]"
      // initial={{ opacity: 0 }}
      // whileInView={{ opacity: 1 }}
      // transition={{ duration: 0.25 }}
      // viewport={{ once: true }}
      // exit={{ opacity: 0 }}
    >
      <div className="w-full bg-[white] rounded-[12px] p-2 relative mt-8">
        <div
          style={{
            backgroundImage: `url(${SelectionDayBg.default})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="h-[110px] w-full absolute -top-[54px] flex justify-center items-center"
        >
          <span
            className={`pb-[2px] text-[black] font-bold ${
              isMobile ? "text-[0.875rem]" : "text-lg"
            } tracking-[-0.36px]`}
          >
            Selection of the day{" "}
            <span className="font-normal">
              ({moment().format("MMM DD, YYYY | ddd")})
            </span>
          </span>
        </div>
        <div className="flex flex-wrap pt-11 pb-[0.75rem] px-[0.75rem] gap-[1.25rem]">
          {filtered?.map((item: any, index: number) => {
            // <div className={`flex gap-[1.25rem]`}>
            //   <PickCard key={index} data={item} />
            // </div>
            // if (filtered.length % 2 === 1) {
            //   if (index === filtered.length - 1) {
            //     return (
            //       <div className="flex gap-[1.25rem] w-full">
            //         <PickCard key={index} data={item} />
            //       </div>
            //     );
            //   }
            //   return (
            //     <div
            //       className="flex gap-[1.25rem] w-1/2"
            //       style={{
            //         width: "calc(50% - 0.625rem)",
            //       }}
            //     >
            //       <PickCard key={index} data={item} />
            //     </div>
            //   );
            // }
            // return (
            //   <div
            //     className="flex gap-[1.25rem] w-1/2"
            //     style={{
            //       width: "calc(50% - 0.625rem)",
            //     }}
            //   >
            //     <PickCard key={index} data={item} />
            //   </div>
            // );
            return (
              <div className="flex gap-[1.25rem] w-full">
                <PickCard key={index} data={item} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
